/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetEpic } from './budget-epic.model';


export interface BudgetTicket { 
    completedAt?: string;
    createdAt?: string;
    epic?: BudgetEpic;
    epicId?: number;
    estimation?: string;
    externalCosts?: number;
    fiscalYearId?: number;
    id?: number;
    identifier?: string;
    plannedHours?: number;
    projectId?: number;
    title?: string;
    type?: string;
    updatedAt?: string;
}

