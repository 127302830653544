import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'time', standalone: true })
export class TimePipe implements PipeTransform {
  transform(date: Date | undefined): string {
    return this.formatTime(this.toDate(date)) ?? '--:--';
  }

  private toDate(date: Date | undefined): Date | null {
    return date ? new Date(date) : null;
  }

  private formatTime(date: Date | null): string | null {
    return date
      ? `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
      : '--:--';
  }
}
