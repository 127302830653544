import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { asSelector, logActions } from './helpers';
import { Selector, Selectors, StateConfig } from './types';
import { produce } from 'immer';

/** Base class for states. Create an implementation by extending it. */
export abstract class BaseState<S extends Record<string, any>> {
  private readonly _store: BehaviorSubject<S>;
  private readonly _defaults: S;

  protected constructor(
    defaults: S,
    private readonly _config: StateConfig = {},
  ) {
    this._defaults = defaults;
    this._store = new BehaviorSubject(this._defaults);

    // set config defaults
    this._config.debug ??= false;
    this._config.useImmer ??= true;

    if (this._config.debug) {
      return logActions(this);
    }
  }

  public asSelector(): Selector<S> {
    return asSelector(this._store, this._config.useImmer);
  }

  public reset(): void {
    this.updateState(() => this._defaults);
  }

  public destroy(): void {
    this._store.complete();
  }

  protected updateState(
    recipe: (currentState: S) => S | void | undefined,
  ): void {
    if (this._config.useImmer) {
      this._store.next(produce(this._store.value, recipe));
    } else {
      recipe(this._store.value);
      this._store.next(this._store.value);
    }

    if (this._config.debug)
      console.log(this.constructor.name, this._store.value);
  }

  /** Selects a slice of the state using by suppling a mapping function. */
  protected select<T>(selectorFn: (state: S) => T): Selector<T> {
    const selection = this._store.pipe(map(selectorFn));
    return asSelector(selection, this._config.useImmer);
  }

  /** Selects a slice of the state using by suppling a mapping function. */
  protected selectSnapshot<T>(selectorFn: (state: S) => T): T {
    return selectorFn(this._store.value);
  }

  /** Combines one or more Selectors and turns them into a new Selector. */
  protected derive<T, Args extends unknown[]>(
    ...args: [...Selectors<Args>, (...functionArgs: Args) => T]
  ): Selector<T> {
    const lastIndex = args.length - 1;
    const selectorFn = args[lastIndex] as (...args: Args) => T;
    const selectors = args.slice(0, lastIndex) as Selectors<Args>;

    const observable = combineLatest(selectors).pipe(
      map((functionArgs) => selectorFn(...(functionArgs as Args))),
    );
    return asSelector(observable, this._config.useImmer);
  }
}
