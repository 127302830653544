/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LicensePayload { 
    email: string;
    includedFeatures: Array<string>;
    name: string;
    'package': string;
    validUntil: string;
}

