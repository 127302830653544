import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  isHelpModeEnabled$ = new BehaviorSubject(false);

  public toggleTooltipsOff(): void {
    return this.isHelpModeEnabled$.next(false);
  }

  public toggleTooltipsOn(): void {
    return this.isHelpModeEnabled$.next(true);
  }
}
