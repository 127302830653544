import {
  AfterViewInit,
  Component,
  DestroyRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, tap } from 'rxjs';
import { IdName } from '../models/id-name.model';
import { HelperService } from '../services/helper.service';
import { DropdownItem } from './dropwdown-item.model';

@Component({
  selector: 'th-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true,
    },
  ],
})
export class DropdownComponent
  implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor
{
  private _value!: any;
  private page = 1;

  @ViewChild('select') private selectComponent!: NgSelectComponent;
  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    if (this._value === value) {
      return;
    }
    this._value = value;
    this.valueChange.emit(this._value);
    this.onChange(value);
  }

  @Input() placeholder = '';
  @Input() labelText = '';
  @Input() tooltipText = '';
  @Input() items: DropdownItem[] | null = [];
  @Input() isDisabled = false;
  @Input() isClearable = true;
  @Input() noMargin = false;
  @Input() isMultiple = false;
  @Input() bindLabel = 'name';
  @Input() bindValue = 'id';
  @Input() addTag = false;
  @Input() createNewOption!: (
    item: string | IdName<string | number>,
  ) => IdName<string | number>;
  @Input() optionTemplate!: TemplateRef<unknown>;
  @Input() itemTemplate!: TemplateRef<unknown>;
  @Input() appendTo = '';
  @Input() lazyLoadItems:
    | ((page: number) => Observable<DropdownItem[]>)
    | null = null;
  @Output() valueChange = new EventEmitter<any>();

  constructor(
    public helperService: HelperService,
    private destroyRef: DestroyRef,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(
      () => this.selectComponent?.setDisabledState(this.isDisabled),
      0,
    );
  }

  ngOnInit(): void {
    if (
      this.isMultiple &&
      this.addTag &&
      Array.isArray(this.value) &&
      !!this.items
    ) {
      const unknownValues = this.value.filter(
        (v) => !this.items?.some((i) => i.id === v),
      );
      unknownValues.forEach((v) => this.items?.push({ id: v, name: v }));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.['isDisabled']?.currentValue !==
      changes?.['isDisabled']?.previousValue
    ) {
      this.selectComponent?.setDisabledState(
        changes?.['isDisabled']?.currentValue as boolean,
      );
    }
  }

  onScrollToEnd(): void {
    if (this.lazyLoadItems) {
      this.page++;
      this.lazyLoadItems(this.page)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          tap((items) => {
            items.forEach((item) =>
              this.selectComponent?.itemsList?.addItem(item),
            );
            this.items?.push(...items);
            this.selectComponent?.detectChanges();
            this.selectComponent?.dropdownPanel?.ngOnChanges({
              items: {
                firstChange: false,
                currentValue: this.items?.map((i) => ({
                  label: i.name,
                  value: i,
                })),
                previousValue: [],
                isFirstChange: () => false,
              },
            });
          }),
        )
        .subscribe();
    }
  }

  resetPage(): void {
    this.page = 0;
  }

  createTag(term: string | IdName<string | number>): IdName<string | number> {
    if (this.createNewOption) {
      return this.createNewOption(term);
    }

    if (typeof term === 'string') {
      return { id: term, name: term };
    } else {
      return term;
    }
  }

  //#region Control Value Accessor
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {};
  writeValue(value: any) {
    if (this._value === value) {
      return;
    }
    this._value = value;
    this.valueChange.emit(this._value);
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  //#endregion
}
