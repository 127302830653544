/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ModelsSprintBudgetAreaCalculationResponse { 
    costs?: number;
    hours?: number;
    id?: number;
    isExceeded?: boolean;
}

