<div class="employee flex gap-4 items-center">
  <div
    *ngIf="picture"
    class="avatar"
    [tp]="employee | employeeName"
    [tpIsEnabled]="!showEmployeeInfo"
  >
    <img class="h-8 w-8 rounded-full bg-dark-gray-800" [src]="picture" alt="" />
  </div>
  <div
    *ngIf="!picture"
    class="h-8 w-8 initials flex items-center justify-center"
    [tp]="employee | employeeName"
    [tpIsEnabled]="!showEmployeeInfo"
  >
    <span>{{ initials }}</span>
  </div>
  <div *ngIf="showEmployeeInfo" class="employee-name-position">
    <p class="font-semibold name">
      {{ employee | employeeName }}
    </p>
    <p class="role text-sm font-medium">{{ 'Senior Developer' }}</p>
  </div>
</div>
