<div
  class="rounded table-container"
  [ngClass]="{ 'border border-dark-gray-700': !borderless }"
  [class.with-pagination]="isPaginatorVisible"
>
  <p-table
    class="table-component"
    [class.with-caption]="isTableCaptionVisible"
    [lazy]="true"
    [lazyLoadOnInit]="!skipInitialLoad"
    (onLazyLoad)="onLazyLoad($event)"
    [value]="rows"
    [columns]="visibleColumns"
    [virtualScroll]="isVirtualScrollingEnabled"
    [virtualRowHeight]="isVirtualScrollingEnabled ? 64 : 0"
    [scrollable]="isVirtualScrollingEnabled"
    [scrollHeight]="isVirtualScrollingEnabled ? '100%' : undefined"
    stateStorage="local"
  >
    <ng-template pTemplate="caption">
      <!-- use for table caption design and isColumnFilteringEnabled-->
      <ng-container *ngIf="isTableCaptionVisible">
        <div
          class="flex gap-5 bg-dark-gray-800 justify-end py-2.5 px-5 border-b border-dark-gray-700 rounded-tl rounded-tr"
        >
          <ng-container *ngIf="hasCustomToolbar">
            <ng-container *ngTemplateOutlet="customToolBarTemplate">
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!hasCustomToolbar">
            <th-button
              *ngIf="isColumnFilteringEnabled"
              [text]="'general.table-configure' | translate"
              matIcon="table_chart"
              [noStyling]="true"
              (click)="toggleColumnVisibility($event)"
            ></th-button>
            <th-button
              *ngIf="isAnyColumnFilterable"
              [class.text-dark-primary]="isFilterActive"
              [text]="'general.table-filter' | translate"
              matIcon="filter_list"
              [noStyling]="true"
              (click)="openFilterDialog($event)"
            ></th-button>
            <th-button
              [text]="
                isFullScreenActive
                  ? ('general.table-fullscreen-off' | translate)
                  : ('general.table-fullscreen-on' | translate)
              "
              [matIcon]="
                isFullScreenActive ? 'close_fullscreen' : 'open_in_full'
              "
              [noStyling]="true"
              [class.text-dark-primary]="isFullScreenActive"
              (click)="toggleFullScreen()"
            ></th-button>
          </ng-container>
        </div>
      </ng-container>
    </ng-template>
    <ng-template *ngIf="showTableHeader" pTemplate="header" let-columns>
      <th
        *ngIf="isRowSelectionEnabled"
        class="w-4 pt-2 z-10 border-b border-dark-gray-700"
      >
        <th-input
          type="checkbox"
          [noMargin]="true"
          (click)="onAllSelectorClick()"
          [value]="!!rows.length && selectedRows.length === rows.length"
        ></th-input>
      </th>
      <th
        *ngFor="let col of columns; let i = index"
        [class.hidden]="!col.isVisible"
        class="border-b border-dark-gray-700"
        [class.centered]="isTableHeaderCentered"
        [class.align-right]="i % 2 !== 0 && isTableHeaderAlignedRight"
      >
        <span
          *ngIf="col.label"
          [class.cursor-pointer]="col?.sortable"
          [class.text-dark-primary]="
            sortState.columnKey === (col?.filterKey ?? col?.propertyKey)
          "
          (click)="col?.sortable ? onSort(col) : null"
        >
          {{
            (translationKeyPrefix
              ? translationKeyPrefix + '.' + col.label
              : $any(col.label)
            ) | translate
          }}
        </span>
        <span
          *ngIf="col?.sortable"
          class="material-symbols-outlined cursor-pointer"
          (click)="onSort(col)"
          >{{ !sortState.columnKey ? 'unfold_more' : '' }}</span
        >
        <span
          *ngIf="col?.sortable"
          [class.text-dark-primary]="
            sortState.columnKey === (col?.filterKey ?? col?.propertyKey)
          "
          class="material-symbols-outlined cursor-pointer"
          (click)="onSort(col)"
          >{{
            sortState.columnKey === (col?.filterKey ?? col?.propertyKey)
              ? sortState.ascending
                ? 'expand_less'
                : 'expand_more'
              : ''
          }}</span
        >
      </th>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-columns="columns"
      let-rowIndex="rowIndex"
      let-row
    >
      <ng-container *ngIf="rowIndex === 0" class="bg-dark-gray-800">
        <ng-container
          *ngTemplateOutlet="preRowsTemplate; context: { $implicit: rows }"
        >
        </ng-container>
      </ng-container>

      <tr
        class="bg-dark-gray-800"
        [class.even-row]="rowIndex % 2 === 0"
        [class.selected]="selectedRows.includes(rowIndex)"
      >
        <td *ngIf="isRowSelectionEnabled" class="w-4">
          <th-input
            type="checkbox"
            [noMargin]="true"
            (click)="onRowSelectorClick(rowIndex)"
            [value]="selectedRows.includes(rowIndex)"
          ></th-input>
        </td>
        <ng-container
          *ngTemplateOutlet="
            rowTemplate;
            context: { $implicit: row, columns: visibleColumns }
          "
        ></ng-container>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="summary"
      let-columns="columns"
      let-rowIndex="rowIndex"
      let-rows
    >
      <ng-container
        *ngTemplateOutlet="postRowsTemplate; context: { $implicit: rows }"
      >
      </ng-container>
    </ng-template>
    <ng-template
      *ngIf="footerTemplate"
      pTemplate="footer"
      class="border-t-[1px] border-dark-gray-700"
    >
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container
    ></ng-template>
  </p-table>
</div>
<div *ngIf="isPaginatorVisible" class="flex items-center mt-5 justify-between">
  <div class="flex gap-5 items-center">
    <th-dropdown
      [(value)]="pageSize"
      [items]="pageOptions"
      [isClearable]="false"
      [noMargin]="true"
      (valueChange)="onDropdownChange()"
      #pageDropdown
    ></th-dropdown>
    <p>{{ pageReport }}</p>
  </div>
  <p-paginator
    class="paginator"
    [rows]="pageSize"
    [first]="firstRowIndex"
    [totalRecords]="totalRowCount"
    (onPageChange)="onLazyLoad($event)"
  ></p-paginator>
</div>
