/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GormcryptoEncryptedValue } from './gormcrypto-encrypted-value.model';
import { BudgetProject } from './budget-project.model';


export interface BudgetTicketSystem { 
    createdAt?: string;
    epicField?: string;
    estimationField?: string;
    estimationTagRegex?: string;
    fallbackToTaskName?: boolean;
    id?: number;
    importSprints?: boolean;
    password?: GormcryptoEncryptedValue;
    project?: BudgetProject;
    projectId?: number;
    proxyUrl?: string;
    sprintTagRegex?: string;
    sprintsField?: string;
    ticketPrefix?: string;
    type?: string;
    updateTickets?: boolean;
    updatedAt?: string;
    url?: string;
    useProxy?: boolean;
    user?: string;
}

