<div class="flex gap-2.5" [class.justify-end]="isRightAligned">
  <div class="flex items-center">
    <label *ngIf="labelText" class="text-base input-label"
      >{{ labelText }}
      <span>
        {{
          value
            ? ('general.active' | translate).toLowerCase()
            : ('general.inactive' | translate).toLowerCase()
        }}</span
      >
    </label>
    <div
      *ngIf="helperService.isHelpModeEnabled$ | async"
      class="tooltip"
      tp="{{ tooltipText }}"
      data-tippy-open
      aria-describedby="tippy-6"
    >
      <span class="icon material-symbols-outlined info-icon">info</span>
    </div>
  </div>
  <div>
    <div class="flipswitch"  (click)="onFlipswitchClick()">
      <input
        type="checkbox"
        name="flipswitch"
        class="flipswitch-checkbox"
        tabindex="0"
        [checked]="value"
      />
      <label
        class="flipswitch-label"
        [ngClass]="{
          'disabled opacity-60': isDisabled,
          'bg-dark-emerald-500': value,
          'bg-dark-gray-200 ': !value
        }"
      >
        <span class="flipswitch-inner"></span>
        <span class="flipswitch-switch bg-dark-gray-50"></span>
      </label>
    </div>
  </div>
</div>
