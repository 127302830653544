/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetTicket } from './budget-ticket.model';
import { ModelsProjectEmployeeViewModels } from './models-project-employee-view-models.model';


export interface ModelsEmployeeActivityViewModel { 
    accountingType?: string;
    billable?: boolean;
    budgetAreaName?: string;
    costs?: number;
    description?: string;
    effortHours?: number;
    id?: number;
    identifier?: string;
    projectEmployee?: ModelsProjectEmployeeViewModels;
    projectEmployeeId?: number;
    ticket?: BudgetTicket;
    ticketId?: number;
    timestamp?: string;
}

