import { Component, Input, OnInit } from '@angular/core';
import { ScreenSizeService } from '../services/screensize.service';

@Component({
  selector: 'th-card-layout',
  templateUrl: './card-layout.component.html',
  styleUrls: ['./card-layout.component.scss'],
})
export class CardLayoutComponent implements OnInit {
  @Input() layout: 'one-column' | 'two-columns' = 'two-columns';
  @Input() fullWidth = false;

  public isSmallScreen = false;

  constructor(public screenSizeService: ScreenSizeService) {}

  ngOnInit() {
    this.screenSizeService.isSmallScreen$.subscribe((isSmallScreen) => {
      this.isSmallScreen = isSmallScreen;
    });
  }

  public get classes(): string {
    // Only use 1 column on smaller devices
    if (this.isSmallScreen) {
      return 'flex flex-col gap-4 mr-6';
    }

    return this.layout === 'one-column'
      ? this.fullWidth
        ? 'flex flex-col gap-4'
        : 'flex flex-col gap-4 mr-6 sm:w-full md:w-1/2'
      : 'grid grid-cols-2 gap-4 mr-6';
  }
}
