import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyMgmtStateService } from '../../company-mgmt/state/company-mgmt.state';

@Pipe({
  name: 'companyName',
})
export class CompanyNamePipe implements PipeTransform {
  constructor(private companyMgmtState: CompanyMgmtStateService) {}

  transform(value: number): Observable<string> {
    if (!value) {
      return of('');
    }

    return this.companyMgmtState.getCompanyName(value);
  }
}
