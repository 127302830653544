/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ModelsEmployeeWorkingHours { 
    day?: number;
    employeeId?: number;
    month?: number;
    week?: number;
}

