import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { EmployeeProject } from '../../../budget/project/models/employee-project.model';
import { Employee } from '../../../budget/project/models/employee.model';
import { environment } from '../../../environment/environment';
import { TableService } from '../../../shared/interfaces/table-service.interface';
import { PagedData } from '../../../shared/models/paged-data.model';
import { Pagination } from '../../../shared/models/pagination.model';
import { Query } from '../../../shared/models/query.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService implements TableService {
  constructor(private httpClient: HttpClient) {}

  loadData(
    pagination: Pagination,
    query: Query,
  ): Observable<PagedData<unknown>> {
    return this.getEmployees(query, pagination);
  }

  getFieldValues(pagination: Pagination, key: string): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${
        environment.budgetApiUrl
      }/admin/employees/field-values?key=${encodeURIComponent(key)}`,
    );
  }

  public getEmployees(
    query: Query,
    pagination: Pagination,
  ): Observable<PagedData<Employee>> {
    return this.httpClient.get<PagedData<Employee>>(
      `${environment.budgetApiUrl}/admin/employees/?Query.Page=${
        pagination.page
      }&Query.Size=${pagination.size}&q=${encodeURIComponent(
        JSON.stringify(query),
      )}`,
    );
  }

  public getEmployee(id: number): Observable<Employee> {
    return this.httpClient.get<Employee>(
      `${environment.budgetApiUrl}/admin/employees/${id}`,
    );
  }

  public updateEmployee(employee: Employee): Observable<Employee> {
    return this.httpClient.patch<Employee>(
      `${environment.budgetApiUrl}/admin/employees/${employee.id}`,
      employee,
    );
  }

  public getEmployeeProjects(id: number): Observable<EmployeeProject[]> {
    return this.httpClient.get<EmployeeProject[]>(
      `${environment.budgetApiUrl}/admin/employees/${id}/projects`,
    );
  }

  public uploadEmployees(companyId: number, file: File): Observable<void> {
    const headers = {
      'x-amz-meta-importtype': 'employees',
      'x-amz-meta-companyid': companyId.toString(),
    };

    return this.httpClient
      .get<{
        url: string;
      }>(
        `${environment.budgetApiUrl}/admin/employees/upload-employees?companyId=${companyId}&name=${encodeURIComponent(file.name)}&contentType=${encodeURIComponent(file.type)}`,
      )
      .pipe(
        switchMap((x) => this.httpClient.put<void>(x.url, file, { headers })),
      );
  }

  public uploadAbsences(companyId: number, file: File): Observable<void> {
    const headers = {
      'x-amz-meta-importtype': 'absences',
      'x-amz-meta-companyid': companyId.toString(),
    };

    return this.httpClient
      .get<{
        url: string;
      }>(
        `${environment.budgetApiUrl}/admin/employees/upload-absences?companyId=${companyId}&name=${encodeURIComponent(file.name)}&contentType=${encodeURIComponent(file.type)}`,
      )
      .pipe(
        switchMap((x) => this.httpClient.put<void>(x.url, file, { headers })),
      );
  }

  public getValidHeadEmployees(employeeId: number): Observable<Employee[]> {
    return this.httpClient.get<Employee[]>(
      `${environment.budgetApiUrl}/admin/employees/${employeeId}/valid-head-employees`,
    );
  }
}
