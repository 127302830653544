<th-dialog
  [dialogData]="dialogData"
  [hasSpacer]="true"
  [hasConfirmButton]="false"
  [customWidth]="'w-80'"
  #dialog
>
  <div class="flex flex-col gap-4 items-start my-5">
    <div
      *ngFor="
        let column of dialogData.columns;
        let first = first;
        let last = last
      "
      class="w-full flex items-center last:pb-1"
    >
      <th-input
        [ngStyle]="{ width: 'auto' }"
        [noMargin]="true"
        type="checkbox"
        [isDisabled]="first"
        [(value)]="column.isVisible"
        (change)="filterColumns(column.propertyKey)"
        [debounce]="200"
      ></th-input>
      <label class="ml-4" [class.text-dark-gray-400]="first">{{
        (dialogData.translationKeyPrefix
          ? dialogData.translationKeyPrefix + '.' + column.label
          : $any(column.label)
        ) | translate
      }}</label>
    </div>
  </div>
</th-dialog>
