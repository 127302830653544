<div>
  <nav class="flex" aria-label="Tabs">
    <a
      *ngFor="let tab of filteredTabs"
      class="tab"
      [hidden]="tab.isHidden"
      [class.selected]="tab.isSelected"
      [class.disabled]="tab.isDisabled"
      (click)="!tab.isDisabled && selectTab(tab)"
      >{{ tab.title | translate }}</a
    >
    <a *ngIf="canAddTab" class="tab h-11" (click)="addTab()"
      ><span class="material-symbols-outlined">add</span></a
    >
  </nav>
</div>
