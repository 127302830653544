import { Component, OnInit } from '@angular/core';
import { UserState } from '../state/user.state';

@Component({
  selector: 'th-auth-callback',
  template: '',
  styles: [],
})
export class AuthCallbackComponent implements OnInit {
  constructor(private userState: UserState) {}

  ngOnInit() {
    this.userState.handleLoginCallback().subscribe();
  }
}
