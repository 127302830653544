/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetEmployee } from './budget-employee.model';


export interface ModelsSprintAvailableEmployee { 
    employee?: BudgetEmployee;
    hasOtherProject?: boolean;
    id?: number;
    projectAssignmentRate?: number;
    projectReservedRate?: number;
    totalHours?: number;
}

