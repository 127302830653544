import { Component, Input } from '@angular/core';

@Component({
  selector: 'th-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent {
  @Input() value!: string;
  @Input() headline!: any;
  @Input() width!: number;

  // to do 100% - x
  //  x = input der jeweiligen komponente
  public get customWidth(): string {
    return `width: ${this.width}px`;
  }
}
