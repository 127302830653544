import { Injectable } from '@angular/core';
import {
  DisplayableTableColumn,
  PrimeNgColumn,
} from '../table/table.component';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class TableStorageService {
  constructor(private storageService: StorageService) {}
  localStorageColumns!: PrimeNgColumn[];
  uniqueTableName!: string;
  row: unknown[] = [];

  getColumns(
    uniqueTableName: string,
    defaultColumns: DisplayableTableColumn[],
  ): DisplayableTableColumn[] {
    const storedColumns =
      this.storageService.get<DisplayableTableColumn[]>(uniqueTableName);
    if (!storedColumns) {
      this.storageService.set(uniqueTableName, defaultColumns);
      return defaultColumns;
    }
    return storedColumns;
  }

  updateColumns(clickedColumnKey: string, uniqueTableName: string): void {
    const localStorageColumns =
      this.storageService.get<DisplayableTableColumn[]>(uniqueTableName) || [];
    const updatedColumns = localStorageColumns.map((column) => {
      if (column.propertyKey === clickedColumnKey) {
        return {
          ...column,
          isVisible: !column.isVisible,
        } as DisplayableTableColumn;
      }
      return column;
    });
    this.storageService.set(uniqueTableName, updatedColumns);
  }
}
