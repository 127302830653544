import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { Portal } from '../../shared/enums/portal.enum';
import { UserRole } from '../../shared/enums/user-role.enum';
import { PagedData } from '../../shared/models/paged-data.model';
import { Query } from '../../shared/models/query.model';
import { UserInfo } from '../../shared/models/user-info.model';
import { BaseState } from '../../shared/state/base.state';
import { Selector } from '../../shared/state/types';
import { UserService } from '../services/user.service';

interface UserStateModel {
  users: UserInfo[];
  currentUser: UserInfo | null;
  passwordLink: string | null;
  isEditModeActive: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserState extends BaseState<UserStateModel> {
  constructor(private userService: UserService) {
    super(
      {
        users: [],
        currentUser: null,
        passwordLink: null,
        isEditModeActive: false,
      },
      { useImmer: false },
    );
  }

  public users$ = this.select(({ users }) => users);
  public currentUser$ = this.select(
    ({ currentUser }) => currentUser as UserInfo,
  );
  public passwordLink$ = this.select(({ passwordLink }) => passwordLink);
  isEditModeActive$: Selector<boolean> = this.select(
    ({ isEditModeActive }) => isEditModeActive,
  );

  public get currentUser(): UserInfo {
    return this.selectSnapshot(({ currentUser }) => currentUser) as UserInfo;
  }

  public toggleEditMode(): void {
    this.updateState((state) => {
      state.isEditModeActive = !state.isEditModeActive;
    });
  }

  public setCurrentUser(userId: number | null): Observable<UserInfo | null> {
    if (userId === null) {
      this.updateState((state) => {
        state.currentUser = null;
      });
      return of(null);
    } else if (userId === 0) {
      const user = {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        companyId: 0,
        isEnabled: true,
        isLocked: false,
        roles: { [Portal.Budget]: UserRole.AdminRole },
        wrongPasswordCounter: 0,
        projectIds: [] as number[],
        employeeId: 0,
      } as UserInfo;
      this.updateState((state) => {
        state.currentUser = user;
        state.isEditModeActive = true;
      });
      return of(user);
    }

    return this.userService.getUser(userId).pipe(
      tap((user) => {
        this.updateState((state) => {
          state.currentUser = user;
        });
      }),
    );
  }

  public loadUsers(): Observable<PagedData<UserInfo>> {
    return this.userService
      .getUsers({ where: {} } as Query, { size: 1, page: 50 })
      .pipe(
        tap((users) => {
          this.updateState((state) => {
            state.users = users.content;
          });
        }),
      );
  }

  public saveCurrentUser(): Observable<
    UserInfo | { user: UserInfo; link: string }
  > {
    if (!this.currentUser.id) {
      return this.userService.createUser(this.currentUser).pipe(
        tap((response) =>
          this.updateState((state) => {
            state.currentUser = response.user;
            state.passwordLink = response.link;
          }),
        ),
      );
    } else {
      return this.userService.updateUser(this.currentUser).pipe(
        tap((user) =>
          this.updateState((state) => {
            state.currentUser = user;
          }),
        ),
      );
    }
  }

  public resetPassword(id: number): Observable<string> {
    return this.userService.resetUserPassword(id).pipe(
      tap((link) => {
        this.updateState((state) => {
          state.passwordLink = link;
        });
      }),
    );
  }
}
