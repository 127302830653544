import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment/environment';
import { LicenseInfo } from '../models/license-info.model';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  constructor(private httpClient: HttpClient) {}

  public getLicenseInfo(): Observable<LicenseInfo> {
    return this.httpClient.get<LicenseInfo>(
      `${environment.coreApiUrl}/user/license`,
    );
  }
}
