import { Component, Input } from '@angular/core';

@Component({
  selector: 'th-card-layout-box',
  templateUrl: './card-layout-box.component.html',
  styleUrls: ['./card-layout-box.component.scss'],
})
export class CardLayoutBoxComponent {
  @Input() title: string | undefined;
  @Input() tooltipText: string | undefined;
  @Input() adjustHeight = true;
  @Input() backgroundColor: string | undefined;
  @Input() height: string | undefined;
}
