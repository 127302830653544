import { Component, Input } from '@angular/core';

@Component({
  selector: 'th-percentage-widget',
  templateUrl: './percentage-widget.component.html',
  styleUrls: ['./percentage-widget.component.scss'],
})
export class PercentageWidgetComponent {
  @Input() displayValue!: any;
  @Input() chartValue!: any;
  @Input() width!: number;
  @Input() description?: string;
  @Input() isDisabled = false;

  public get customWidth(): string {
    return `--p: ${this.width}`;
  }
}
