<div>
  <label *ngIf="labelText" class="flex text-base"
    >{{ labelText }}
    <div
      *ngIf="helperService.isHelpModeEnabled$ | async"
      class="tooltip"
      tp="{{ tooltipText }}"
      data-tippy-open
      aria-describedby="tippy-6"
    >
      <span class="icon material-symbols-outlined input-info">info</span>
    </div></label
  >

  <ng-select
    [class.mt-2]="!noMargin"
    [items]="items"
    [appendTo]="appendTo"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [placeholder]="placeholder"
    [clearable]="isClearable"
    [(ngModel)]="value"
    [multiple]="isMultiple"
    [addTag]="addTag ? createTag.bind(this) : false"
    (blur)="onTouch(value)"
    (scrollToEnd)="onScrollToEnd()"
    (close)="resetPage()"
    #select
  >
    <ng-template *ngIf="optionTemplate" ng-option-tmp let-item="item">
      <ng-container *ngTemplateOutlet="optionTemplate; context: { item: item }">
      </ng-container>
    </ng-template>
    <ng-template *ngIf="itemTemplate" ng-label-tmp let-item="item">
      <ng-container
        *ngTemplateOutlet="itemTemplate; context: { item: item }"
        class="flex text-sm font-medium"
      >
      </ng-container>
    </ng-template>
  </ng-select>
</div>
