/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModelsWorkingConstraintViolation } from './models-working-constraint-violation.model';


export interface ModelsDailyAttendanceOverview { 
    bookedHours?: number;
    constraintViolations?: Array<ModelsWorkingConstraintViolation>;
    date?: string;
    saldo?: number;
    workedHours?: number;
}

