import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public get<T>(key: string): T {
    try {
      const obj = JSON.parse(localStorage?.getItem(key) || '') as T;
      return typeof obj === 'object' ? obj : (obj?.toString() as T);
    } catch {
      return localStorage?.getItem(key) as T;
    }
  }

  public set(key: string, value: any): void {
    localStorage?.setItem(
      key,
      typeof value === 'object' ? JSON.stringify(value) : value
    );
  }

  public remove(key: string): void {
    localStorage?.removeItem(key);
  }

  public clear(): void {
    localStorage?.clear();
  }
}
