import { Injectable } from '@angular/core';
import { catchError, from, Observable, of, tap, throwError } from 'rxjs';
import { UserInfo } from '../models/user-info.model';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { BaseState } from './base.state';

interface UserModel {
  isInitialized: boolean;
  userInfo: UserInfo | null;
  profilePicture: string;
}

const StorageKey = 'townhall-credentials';

@Injectable({
  providedIn: 'root',
})
export class UserState extends BaseState<UserModel> {
  private static randomProfilePicture(): string {
    const imageArray = [
      '/assets/male_bighead2.svg',
      '/assets/bighead_female.svg',
    ];
    const randomIndex = Math.floor(Math.random() * imageArray.length);
    return imageArray[randomIndex];
  }

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
  ) {
    super(
      {
        isInitialized: false,
        userInfo: null,
        profilePicture: UserState.randomProfilePicture(),
      },
      { useImmer: false },
    );
  }

  public profilePicture$ = this.select(({ profilePicture }) => profilePicture);
  public userInfo$ = this.select(({ userInfo }) => userInfo);
  public isAuthenticated$ = this.authService.isAuthenticated$;

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated$.value;
  }

  get userInfo(): UserInfo | null {
    return this.selectSnapshot(({ userInfo }) => userInfo);
  }

  async getAuthorizationHeaderValue(
    isRefreshTokenRequest = false,
  ): Promise<string> {
    if (this.authService.isAuthenticated$.value) {
      if (this.authService.getRemainingTime() <= 30 && !isRefreshTokenRequest) {
        await this.authService.refreshToken();
      }

      return `Bearer ${this.authService.getIdToken()}`;
    }

    return '';
  }

  public getEmulationToken(): string {
    return this.authService.getEmulationToken();
  }

  public clearEmulationToken(): void {
    this.authService.clearEmulationToken();
  }

  public stopEmulation(): void {
    this.authService.stopEmulation();
  }

  public initialize(): Observable<UserInfo | null> {
    if (!this.selectSnapshot(({ isInitialized }) => isInitialized)) {
      this.authService.initOAuthService();

      if (this.isAuthenticated) {
        return this.authService.getUserInfos().pipe(
          tap((info) =>
            this.updateState((state) => {
              state.userInfo = info;
              state.isInitialized = true;
            }),
          ),
          catchError(() => {
            this.updateState((state) => {
              state.isInitialized = true;
              state.userInfo = null;
            });
            this.isAuthenticated$.next(false);
            return of(null);
          }),
        );
      }

      this.updateState((state) => {
        state.isInitialized = true;
        state.userInfo = null;
      });
      this.isAuthenticated$.next(false);
      return of(null);
    }

    this.updateState((state) => {
      state.isInitialized = true;
    });
    return of(null);
  }

  public login(
    email: string,
    password: string,
  ): Observable<{ info: UserInfo; token: string } | null> {
    return from(this.authService.login(email, password)).pipe(
      tap((response) =>
        this.updateState((state) => {
          state.userInfo = response.info;
        }),
      ),
      catchError((error) => {
        this.logout();
        return throwError(() => error);
      }),
    );
  }

  public loginAzure(): Observable<void> {
    return from(this.authService.loginAzure());
  }

  public handleLoginCallback(): Observable<UserInfo | null> {
    return from(this.authService.handleLoginCallback()).pipe(
      tap((info) =>
        this.updateState((state) => {
          state.userInfo = info;
        }),
      ),
      catchError((error) => {
        this.logout();
        return throwError(() => error);
      }),
    );
  }

  public logout(): void {
    this.authService.logOff();
    this.updateState((state) => {
      state.userInfo = null;
    });
    this.storageService.remove(StorageKey);
  }
}
