import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment/environment';
import { UserInfo } from '../models/user-info.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  public getUserInfos(): Observable<UserInfo> {
    return this.httpClient.get<UserInfo>(`${environment.coreApiUrl}/user/info`);
  }

  public createBasicToken(
    email: string,
    password: string,
  ): Observable<{ info: UserInfo; token: string }> {
    return this.httpClient.post<{ info: UserInfo; token: string }>(
      `${environment.coreApiUrl}/user/token`,
      { email, password },
    );
  }

  public refreshBasicToken(token: string): Observable<{ token: string }> {
    return this.httpClient.post<{ token: string }>(
      `${environment.coreApiUrl}/user/token/refresh`,
      { token },
    );
  }
}
