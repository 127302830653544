import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tab } from '../models/tab.model';
import { RoleService } from '../services/role.service';

@Component({
  selector: 'th-tabbar',
  templateUrl: './tabbar.component.html',
  styleUrls: ['./tabbar.component.scss'],
})
export class TabbarComponent implements OnInit {
  @Input({ required: true }) tabs!: Tab[];
  @Input() canAddTab = false;
  @Output() tabChanged = new EventEmitter<Tab>();
  @Output() tabAdded = new EventEmitter<void>();

  public get filteredTabs(): Tab[] {
    return this.tabs.filter(
      (tab) =>
        tab.neededRoles?.length === 0 ||
        this.roleService.isAuthorized(tab.neededRoles ?? []),
    );
  }

  constructor(private roleService: RoleService) {}

  ngOnInit(): void {
    if (!this.filteredTabs.some((t) => t.isSelected)) {
      this.selectTab(this.filteredTabs[0], false);
    }
  }

  public get selectedTabIndex(): number {
    return this.tabs.findIndex((t) => t.isSelected);
  }

  public selectTab(tab: Tab, notify = true): void {
    this.tabs.forEach((t) => (t.isSelected = tab?.id === t.id));
    if (notify) {
      this.tabChanged.emit(tab);
    }
  }

  public addTab(): void {
    this.tabs.forEach((t) => (t.isSelected = false));
    this.tabAdded.emit();
  }
}
