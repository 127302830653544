/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetProjectEmployee } from './budget-project-employee.model';
import { BudgetBudgetArea } from './budget-budget-area.model';


export interface BudgetSprintEmployee { 
    budgetArea?: BudgetBudgetArea;
    budgetAreaId?: number;
    createdAt?: string;
    id?: number;
    projectEmployee?: BudgetProjectEmployee;
    projectEmployeeId?: number;
    sprintId?: number;
    updatedAt?: string;
    workPercentage?: number;
}

