/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BudgetDashboard } from '../model/budget-dashboard.model';
// @ts-ignore
import { BudgetDashboardPreset } from '../model/budget-dashboard-preset.model';
// @ts-ignore
import { RepositoriesDashboardViewModel } from '../model/repositories-dashboard-view-model.model';
// @ts-ignore
import { RepositoriesPresetViewModel } from '../model/repositories-preset-view-model.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface DeleteDashboardPresetRequestParams {
    /** Preset ID */
    presetId: number;
}

export interface GetCompanyDashboardWidgetDataRequestParams {
    /** Company ID */
    companyId: number;
    /** Dashboard ID */
    dashboardId: number;
    /** Start date for data retrieval (RFC3339 format) */
    queryStartDate: string;
    /** End date for data retrieval (RFC3339 format) */
    queryEndDate: string;
    /** Name of the widget */
    queryWidgetName: string;
}

export interface GetDashboardRequestParams {
    /** Dashboard ID to retrieve */
    dashboardId: number;
}

export interface GetDashboardPresetRequestParams {
    /** ID of the dashboard preset */
    presetId: number;
}

export interface GetDashboardPresetsRequestParams {
    /** Type of the dashboard to retrieve presets for (\&#39;project\&#39;, \&#39;company\&#39;, \&#39;sprint\&#39;) */
    queryDashboardType: string;
}

export interface GetDashboardWidgetsByEntityRequestParams {
    /** Type of dashboard to retrieve widgets for (\&#39;project\&#39;, \&#39;company\&#39;, \&#39;sprint\&#39;) */
    queryDashboardType: string;
}

export interface GetDashboardsRequestParams {
    /** Type of dashboard to retrieve (\&#39;project\&#39;, \&#39;company\&#39;, \&#39;sprint\&#39;) */
    queryDashboardType: string;
}

export interface GetProjectDashboardWidgetDataRequestParams {
    /** Project ID */
    projectId: number;
    /** Dashboard ID */
    dashboardId: number;
    /** Start date for data retrieval (RFC3339 format) */
    queryStartDate: string;
    /** End date for data retrieval (RFC3339 format) */
    queryEndDate: string;
    /** Name of the widget */
    queryWidgetName: string;
}

export interface GetSprintDashboardWidgetDataRequestParams {
    /** Sprint ID */
    sprintId: number;
    /** Dashboard ID */
    dashboardId: number;
    /** Start date for the data range in ISO 8601 format (RFC3339) */
    queryStartDate: string;
    /** End date for the data range in ISO 8601 format (RFC3339) */
    queryEndDate: string;
    /** Name of the widget */
    queryWidgetName: string;
}

export interface SaveDashboardPresetRequestParams {
    /** Dashboard preset data */
    preset: BudgetDashboardPreset;
}


@Injectable({
  providedIn: 'root'
})
export class DashboardsService {

    protected basePath = 'https://api.townhall.nerdware.dev/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Delete a dashboard preset
     * Deletes a dashboard preset based on the preset ID provided.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDashboardPreset(requestParameters?: DeleteDashboardPresetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
    public deleteDashboardPreset(requestParameters?: DeleteDashboardPresetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
    public deleteDashboardPreset(requestParameters?: DeleteDashboardPresetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
    public deleteDashboardPreset(requestParameters?: DeleteDashboardPresetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const presetId = requestParameters?.presetId;
        if (presetId === null || presetId === undefined) {
            throw new Error('Required parameter presetId was null or undefined when calling deleteDashboardPreset.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/dashboards/presets/${this.configuration.encodeParam({name: "presetId", value: presetId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve widget data for a company dashboard
     * Fetches data for a specific widget on a company dashboard for the provided date range.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyDashboardWidgetData(requestParameters?: GetCompanyDashboardWidgetDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<{ [key: string]: object; }>;
    public getCompanyDashboardWidgetData(requestParameters?: GetCompanyDashboardWidgetDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<{ [key: string]: object; }>>;
    public getCompanyDashboardWidgetData(requestParameters?: GetCompanyDashboardWidgetDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<{ [key: string]: object; }>>;
    public getCompanyDashboardWidgetData(requestParameters?: GetCompanyDashboardWidgetDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const companyId = requestParameters?.companyId;
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyDashboardWidgetData.');
        }
        const dashboardId = requestParameters?.dashboardId;
        if (dashboardId === null || dashboardId === undefined) {
            throw new Error('Required parameter dashboardId was null or undefined when calling getCompanyDashboardWidgetData.');
        }
        const queryStartDate = requestParameters?.queryStartDate;
        if (queryStartDate === null || queryStartDate === undefined) {
            throw new Error('Required parameter queryStartDate was null or undefined when calling getCompanyDashboardWidgetData.');
        }
        const queryEndDate = requestParameters?.queryEndDate;
        if (queryEndDate === null || queryEndDate === undefined) {
            throw new Error('Required parameter queryEndDate was null or undefined when calling getCompanyDashboardWidgetData.');
        }
        const queryWidgetName = requestParameters?.queryWidgetName;
        if (queryWidgetName === null || queryWidgetName === undefined) {
            throw new Error('Required parameter queryWidgetName was null or undefined when calling getCompanyDashboardWidgetData.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryStartDate !== undefined && queryStartDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryStartDate, 'Query.startDate');
        }
        if (queryEndDate !== undefined && queryEndDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryEndDate, 'Query.endDate');
        }
        if (queryWidgetName !== undefined && queryWidgetName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryWidgetName, 'Query.widgetName');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/dashboards/company/${this.configuration.encodeParam({name: "dashboardId", value: dashboardId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/data/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<{ [key: string]: object; }>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a specific dashboard
     * Gets detailed information about a specific dashboard by its ID.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDashboard(requestParameters?: GetDashboardRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<RepositoriesDashboardViewModel>;
    public getDashboard(requestParameters?: GetDashboardRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RepositoriesDashboardViewModel>>;
    public getDashboard(requestParameters?: GetDashboardRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RepositoriesDashboardViewModel>>;
    public getDashboard(requestParameters?: GetDashboardRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const dashboardId = requestParameters?.dashboardId;
        if (dashboardId === null || dashboardId === undefined) {
            throw new Error('Required parameter dashboardId was null or undefined when calling getDashboard.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/dashboards/${this.configuration.encodeParam({name: "dashboardId", value: dashboardId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<RepositoriesDashboardViewModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a dashboard preset
     * Fetches a single dashboard preset based on its ID.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDashboardPreset(requestParameters?: GetDashboardPresetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<RepositoriesPresetViewModel>;
    public getDashboardPreset(requestParameters?: GetDashboardPresetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<RepositoriesPresetViewModel>>;
    public getDashboardPreset(requestParameters?: GetDashboardPresetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<RepositoriesPresetViewModel>>;
    public getDashboardPreset(requestParameters?: GetDashboardPresetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const presetId = requestParameters?.presetId;
        if (presetId === null || presetId === undefined) {
            throw new Error('Required parameter presetId was null or undefined when calling getDashboardPreset.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/dashboards/presets/${this.configuration.encodeParam({name: "presetId", value: presetId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<RepositoriesPresetViewModel>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve dashboard presets
     * Fetches presets for dashboards based on the specified dashboard type.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDashboardPresets(requestParameters?: GetDashboardPresetsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<BudgetDashboardPreset>>;
    public getDashboardPresets(requestParameters?: GetDashboardPresetsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<BudgetDashboardPreset>>>;
    public getDashboardPresets(requestParameters?: GetDashboardPresetsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<BudgetDashboardPreset>>>;
    public getDashboardPresets(requestParameters?: GetDashboardPresetsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const queryDashboardType = requestParameters?.queryDashboardType;
        if (queryDashboardType === null || queryDashboardType === undefined) {
            throw new Error('Required parameter queryDashboardType was null or undefined when calling getDashboardPresets.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryDashboardType !== undefined && queryDashboardType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryDashboardType, 'Query.dashboardType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/dashboards/presets/`;
        return this.httpClient.request<Array<BudgetDashboardPreset>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve available widgets for a dashboard
     * Fetches all available widgets based on the specified dashboard type.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDashboardWidgetsByEntity(requestParameters?: GetDashboardWidgetsByEntityRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<string>>;
    public getDashboardWidgetsByEntity(requestParameters?: GetDashboardWidgetsByEntityRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<string>>>;
    public getDashboardWidgetsByEntity(requestParameters?: GetDashboardWidgetsByEntityRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<string>>>;
    public getDashboardWidgetsByEntity(requestParameters?: GetDashboardWidgetsByEntityRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const queryDashboardType = requestParameters?.queryDashboardType;
        if (queryDashboardType === null || queryDashboardType === undefined) {
            throw new Error('Required parameter queryDashboardType was null or undefined when calling getDashboardWidgetsByEntity.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryDashboardType !== undefined && queryDashboardType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryDashboardType, 'Query.dashboardType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/dashboards/available-widgets`;
        return this.httpClient.request<Array<string>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve all dashboards based on type
     * Fetches all available dashboards based on the specified type.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDashboards(requestParameters?: GetDashboardsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<Array<BudgetDashboard>>;
    public getDashboards(requestParameters?: GetDashboardsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<Array<BudgetDashboard>>>;
    public getDashboards(requestParameters?: GetDashboardsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<Array<BudgetDashboard>>>;
    public getDashboards(requestParameters?: GetDashboardsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const queryDashboardType = requestParameters?.queryDashboardType;
        if (queryDashboardType === null || queryDashboardType === undefined) {
            throw new Error('Required parameter queryDashboardType was null or undefined when calling getDashboards.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryDashboardType !== undefined && queryDashboardType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryDashboardType, 'Query.dashboardType');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/dashboards`;
        return this.httpClient.request<Array<BudgetDashboard>>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve widget data for a project dashboard
     * Fetches data for a specific widget on a project dashboard using the provided dates.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDashboardWidgetData(requestParameters?: GetProjectDashboardWidgetDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<{ [key: string]: object; }>;
    public getProjectDashboardWidgetData(requestParameters?: GetProjectDashboardWidgetDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<{ [key: string]: object; }>>;
    public getProjectDashboardWidgetData(requestParameters?: GetProjectDashboardWidgetDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<{ [key: string]: object; }>>;
    public getProjectDashboardWidgetData(requestParameters?: GetProjectDashboardWidgetDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const projectId = requestParameters?.projectId;
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectDashboardWidgetData.');
        }
        const dashboardId = requestParameters?.dashboardId;
        if (dashboardId === null || dashboardId === undefined) {
            throw new Error('Required parameter dashboardId was null or undefined when calling getProjectDashboardWidgetData.');
        }
        const queryStartDate = requestParameters?.queryStartDate;
        if (queryStartDate === null || queryStartDate === undefined) {
            throw new Error('Required parameter queryStartDate was null or undefined when calling getProjectDashboardWidgetData.');
        }
        const queryEndDate = requestParameters?.queryEndDate;
        if (queryEndDate === null || queryEndDate === undefined) {
            throw new Error('Required parameter queryEndDate was null or undefined when calling getProjectDashboardWidgetData.');
        }
        const queryWidgetName = requestParameters?.queryWidgetName;
        if (queryWidgetName === null || queryWidgetName === undefined) {
            throw new Error('Required parameter queryWidgetName was null or undefined when calling getProjectDashboardWidgetData.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryStartDate !== undefined && queryStartDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryStartDate, 'Query.startDate');
        }
        if (queryEndDate !== undefined && queryEndDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryEndDate, 'Query.endDate');
        }
        if (queryWidgetName !== undefined && queryWidgetName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryWidgetName, 'Query.widgetName');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/dashboards/project/${this.configuration.encodeParam({name: "dashboardId", value: dashboardId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/data/${this.configuration.encodeParam({name: "projectId", value: projectId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<{ [key: string]: object; }>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve widget data for a sprint dashboard
     * Fetches data for a specified widget within a sprint dashboard based on the provided date range and sprint ID.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSprintDashboardWidgetData(requestParameters?: GetSprintDashboardWidgetDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<{ [key: string]: object; }>;
    public getSprintDashboardWidgetData(requestParameters?: GetSprintDashboardWidgetDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<{ [key: string]: object; }>>;
    public getSprintDashboardWidgetData(requestParameters?: GetSprintDashboardWidgetDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<{ [key: string]: object; }>>;
    public getSprintDashboardWidgetData(requestParameters?: GetSprintDashboardWidgetDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const sprintId = requestParameters?.sprintId;
        if (sprintId === null || sprintId === undefined) {
            throw new Error('Required parameter sprintId was null or undefined when calling getSprintDashboardWidgetData.');
        }
        const dashboardId = requestParameters?.dashboardId;
        if (dashboardId === null || dashboardId === undefined) {
            throw new Error('Required parameter dashboardId was null or undefined when calling getSprintDashboardWidgetData.');
        }
        const queryStartDate = requestParameters?.queryStartDate;
        if (queryStartDate === null || queryStartDate === undefined) {
            throw new Error('Required parameter queryStartDate was null or undefined when calling getSprintDashboardWidgetData.');
        }
        const queryEndDate = requestParameters?.queryEndDate;
        if (queryEndDate === null || queryEndDate === undefined) {
            throw new Error('Required parameter queryEndDate was null or undefined when calling getSprintDashboardWidgetData.');
        }
        const queryWidgetName = requestParameters?.queryWidgetName;
        if (queryWidgetName === null || queryWidgetName === undefined) {
            throw new Error('Required parameter queryWidgetName was null or undefined when calling getSprintDashboardWidgetData.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (queryStartDate !== undefined && queryStartDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryStartDate, 'Query.startDate');
        }
        if (queryEndDate !== undefined && queryEndDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryEndDate, 'Query.endDate');
        }
        if (queryWidgetName !== undefined && queryWidgetName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>queryWidgetName, 'Query.widgetName');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/dashboards/sprint/${this.configuration.encodeParam({name: "dashboardId", value: dashboardId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}/data/${this.configuration.encodeParam({name: "sprintId", value: sprintId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: undefined})}`;
        return this.httpClient.request<{ [key: string]: object; }>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save a dashboard preset
     * Creates a new dashboard preset or updates an existing one based on the provided preset data.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveDashboardPreset(requestParameters?: SaveDashboardPresetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<BudgetDashboardPreset>;
    public saveDashboardPreset(requestParameters?: SaveDashboardPresetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<BudgetDashboardPreset>>;
    public saveDashboardPreset(requestParameters?: SaveDashboardPresetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<BudgetDashboardPreset>>;
    public saveDashboardPreset(requestParameters?: SaveDashboardPresetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const preset = requestParameters?.preset;
        if (preset === null || preset === undefined) {
            throw new Error('Required parameter preset was null or undefined when calling saveDashboardPreset.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/user/dashboards/presets/`;
        return this.httpClient.request<BudgetDashboardPreset>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: preset,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
