<div
  class="mb-3 pb-3 border-b border-dark-gray-750 flex gap-5 items-center gap-x-6 mr-6 flex-wrap"
>
  <th-bread-crumb
    [nameResolver]="nameResolver"
    [app]="'budget'"
  ></th-bread-crumb>

  <div class="grow"></div>
  <div class="flex flex-col md:flex-row gap-4 ml-auto justify-end items-center">
    <ng-content></ng-content>
  </div>
</div>
