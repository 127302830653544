/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetProjectEmployee } from './budget-project-employee.model';
import { Tag } from './tag.model';
import { BudgetProjectAccountingUnit } from './budget-project-accounting-unit.model';


export interface EmployeeSalaryInfo { 
    currentAccountingUnit?: BudgetProjectAccountingUnit;
    employee?: BudgetProjectEmployee;
    tags?: Array<Tag>;
}

