/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CoreUpdateUser { 
    canEmulate?: boolean;
    canRequestEmulation?: boolean;
    companyId?: number;
    email?: string;
    firstName?: string;
    id?: number;
    isEnabled?: boolean;
    isLocked?: boolean;
    lastName?: string;
    projectIds?: Array<number>;
    roles?: { [key: string]: string; };
    wrongPasswordCounter?: number;
}

