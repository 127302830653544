import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment/environment';
import { EmulationSession } from '../models/emulation-session.model';

@Injectable({
  providedIn: 'root',
})
export class EmulationService {
  constructor(private httpClient: HttpClient) {}

  public getOwnPendingSessionRequests(): Observable<EmulationSession[]> {
    return this.httpClient.get<EmulationSession[]>(
      `${environment.budgetApiUrl}/admin/emulation/own-pending`,
    );
  }

  public requestEmulationSession(
    userId: number,
    targetUserId: number,
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.budgetApiUrl}/admin/emulation/request?userId=${userId}&targetUserId=${targetUserId}`,
      {},
    );
  }

  public confirmEmulationSession(
    userId: number,
    targetUserId: number,
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.budgetApiUrl}/admin/emulation/confirm?userId=${userId}&targetUserId=${targetUserId}`,
      {},
    );
  }

  public deleteEmulationSession(
    userId: number,
    targetUserId: number,
  ): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.budgetApiUrl}/admin/emulation/?userId=${userId}&targetUserId=${targetUserId}`,
    );
  }

  public createEmulationToken(
    userId: number,
    targetUserId: number,
  ): Observable<{ token: string }> {
    return this.httpClient.post<{ token: string }>(
      `${environment.budgetApiUrl}/admin/emulation/token?userId=${userId}&targetUserId=${targetUserId}`,
      {},
    );
  }
}
