import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class CollapseSidebarService {
  private _isUserCollapse = false;
  public set isUserCollapse(value: boolean) {
    this._isUserCollapse = value;
  }
  public get isUserCollapse(): boolean {
    return this._isUserCollapse;
  }

  isSidebarCollapsed$ = new BehaviorSubject(false);
  constructor(private storageService: StorageService) {}

  public get isSidebarCollapsed(): boolean {
    return this.storageService.get('th-sidebar') === 'collapse' ? true : false;
  }

  public toggleUserCollapse() {
    this._isUserCollapse = !this._isUserCollapse;
  }

  public set(value: boolean) {
    this.isSidebarCollapsed$.next(value);

    this.storageService.set(
      'th-sidebar',
      this.isSidebarCollapsed$.value ? 'collapse' : 'expand',
    );
  }
}
