<div role="main" id="main" class="base-container">
  <div
    class="base-container theme-bg"
    [class.landing-page-bg]="themeService.isOnLandingPage"
  >
    <ng-container *ngIf="isGlobalSidebarVisible">
      <th-sidebar
        app="global"
        [nameResolver]="
          townhallNameResolver.resolveName.bind(townhallNameResolver)
        "
        class="base-container"
      >
        <router-outlet></router-outlet>
      </th-sidebar>
    </ng-container>
    <ng-container *ngIf="!isGlobalSidebarVisible">
      <router-outlet></router-outlet>
    </ng-container>
  </div>
</div>

<ngx-spinner
  *ngIf="spinnerService.shouldLoad$ | async"
  name="th-spinner"
  type="ball-clip-rotate"
  [bdColor]="
    (themeService.isDarkModeEnabled$ | async)
      ? 'rgba(215, 215, 215, 0.5)'
      : 'rgba(51, 51, 51, 0.5)'
  "
  [fullScreen]="true"
  ><p class="text-white text-center pt-6">
    {{ 'loading.data' | translate }}
  </p></ngx-spinner
>
