<div
  *ngIf="helperService.isHelpModeEnabled$ | async"
  class="view-information-container col-span-2 grid grid-cols-3 gap-4 bg-dark-gray-700 p-2 rounded-md"
>
  <div class="info-text col-span-2">
    <div
      class="icon mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
    >
      <span class="icon material-symbols-outlined">{{ icon }}</span>
    </div>
    <p>
      {{ text }}
    </p>
  </div>
</div>
