import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HelperService } from '../services/helper.service';

@Component({
  selector: 'th-flipswitch',
  templateUrl: './flipswitch.component.html',
  styleUrls: ['./flipswitch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlipswitchComponent),
      multi: true,
    },
  ],
})
export class FlipswitchComponent implements ControlValueAccessor {
  private _value = false;
  get value(): boolean {
    return this._value;
  }
  set value(value: boolean) {
    if (this._value === value) {
      return;
    }
    this._value = value;
    this.onChange(value);
  }

  @Input() labelText = '';
  @Input() isDisabled = false;
  @Input() tooltipText? = '';
  @Input() isRightAligned = false;

  constructor(public helperService: HelperService) {}



  onFlipswitchClick() {
    if (!this.isDisabled) {
      this.value = !this.value;
    }
  }
  //#region Control Value Accessor
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {};
  writeValue(value: any) {
    if (this._value === value) {
      return;
    }
    this._value = value;
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
  //#endregion
}
