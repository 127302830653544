/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModelsUsedBudgetAreaPart } from './models-used-budget-area-part.model';


export interface ModelsUsedBudgetArea { 
    budgetSum?: number;
    fiscalYearId?: number;
    name?: string;
    parts?: Array<ModelsUsedBudgetAreaPart>;
    usedPercentage?: number;
    usedSum?: number;
}

