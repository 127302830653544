import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment/environment';
import { TableService } from '../../shared/interfaces/table-service.interface';
import { PagedData } from '../../shared/models/paged-data.model';
import { Pagination } from '../../shared/models/pagination.model';
import { Query } from '../../shared/models/query.model';
import { UserInfo } from '../../shared/models/user-info.model';

@Injectable({
  providedIn: 'root',
})
export class UserService implements TableService {
  constructor(private httpClient: HttpClient) {}

  loadData(
    pagination: Pagination,
    query: Query,
  ): Observable<PagedData<unknown>> {
    return this.getUsers(query, pagination);
  }

  getFieldValues(pagination: Pagination, key: string): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${
        environment.budgetApiUrl
      }/admin/users/field-values?key=${encodeURIComponent(key)}`,
    );
  }

  public getUsers(
    query: Query,
    pagination: Pagination,
  ): Observable<PagedData<UserInfo>> {
    return this.httpClient.get<PagedData<UserInfo>>(
      `${environment.budgetApiUrl}/admin/users/?Query.Page=${
        pagination.page
      }&Query.Size=${pagination.size}&q=${encodeURIComponent(
        JSON.stringify(query),
      )}`,
    );
  }

  public getUser(id: number): Observable<UserInfo> {
    return this.httpClient.get<UserInfo>(
      `${environment.budgetApiUrl}/admin/users/${id}`,
    );
  }

  public createUser(
    user: UserInfo,
  ): Observable<{ user: UserInfo; link: string }> {
    return this.httpClient.put<{ user: UserInfo; link: string }>(
      `${environment.budgetApiUrl}/admin/users/`,
      user,
    );
  }

  public updateUser(user: UserInfo): Observable<UserInfo> {
    return this.httpClient.patch<UserInfo>(
      `${environment.budgetApiUrl}/admin/users/${user.id}`,
      user,
    );
  }

  public resetUserPassword(id: number): Observable<string> {
    return this.httpClient.post<string>(
      `${environment.budgetApiUrl}/admin/users/${id}/password`,
      { responseType: 'text' },
    );
  }
}
