import { inject, Injectable, RendererFactory2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  isDarkModeEnabled$ = new BehaviorSubject(true);
  private renderer = inject(RendererFactory2).createRenderer(null, null);

  public get isOnLandingPage(): boolean {
    return this.router.url === '/' || this.router.url === '/login';
  }

  constructor(
    private storageService: StorageService,
    private router: Router,
  ) {
    this.isDarkModeEnabled$.next(
      this.storageService.get<string>('townhall-theme') !== 'light',
    );
    this.syncDarkModeWithBody();
  }

  public toggle(): void {
    this.isDarkModeEnabled$.next(!this.isDarkModeEnabled$.value);
    this.storageService.set(
      'townhall-theme',
      this.isDarkModeEnabled$.value ? 'dark' : 'light',
    );
  }

  private syncDarkModeWithBody(): void {
    this.isDarkModeEnabled$
      .pipe(takeUntilDestroyed())
      .subscribe((isDarkModeEnabled) => {
        const body = document.body;
        const className = 'dark';

        isDarkModeEnabled
          ? this.renderer.addClass(body, className)
          : this.renderer.removeClass(body, className);
      });
  }
}
