<div
  class="budget-tooltip-container"
  (mouseenter)="isVisible = true"
  (mouseleave)="isVisible = false"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <ng-content></ng-content>
</div>

<ng-template
  cdk-connected-overlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isVisible"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom'
    }
  ]"
>
  <div
    class="bg-black text-white text-sm p-3 rounded-lg shadow-lg"
    style="background-color: rgba(0, 0, 0, 1)"
  >
    <div class="flex justify-between mb-4 font-bold">
      <span class="text-gray-300"> {{ budgetAreaReporting.name }}</span>
      <span class="text-400">
        {{ 'general.months.month-' + part?.month | translate }}</span
      >
    </div>

    <div class="grid grid-cols-2 gap-x-2 mb-1">
      <span>{{
        'tooltip.project-budget-reporting.planned-budget' | translate
      }}</span>
      <span class="text-right">
        {{ part?.budget | numberFormat: true : true }} €</span
      >
    </div>
    <div class="grid grid-cols-2 gap-x-2 mb-4">
      <span>{{ getUsedBudgetLabelKey | translate }}</span>
      <span class="text-right">
        {{ part?.usedSum | numberFormat: true : true }} €</span
      >
    </div>

    <div
      *ngIf="part?.usedPercentage !== 9999900"
      class="text-right"
      [ngClass]="{
        'budget-green': (part?.usedPercentage ?? 0) < 70,
        'budget-yellow':
          (part?.usedPercentage ?? 0) >= 70 &&
          (part?.usedPercentage ?? 0) <= 100,
        'budget-red': (part?.usedPercentage ?? 0) > 100
      }"
    >
      {{
        (part?.usedPercentage ?? 0) === 0
          ? '0'
          : (part?.usedPercentage ?? 0 | numberFormat)
      }}
      %
    </div>
  </div>
</ng-template>
