/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetBudgetArea } from './budget-budget-area.model';


export interface TownhallcorePageableBudgetBudgetArea { 
    content?: Array<BudgetBudgetArea>;
    hasMore?: boolean;
    page?: number;
    size?: number;
    totalSize?: number;
}

