import { Component, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogComponent, DialogData } from '../../../dialog/dialog.component';
import { IDialog } from '../../../dialog/dialog.interface';
import { TableStorageService } from '../../../services/table-data.service';
import { InternalTableColumn } from '../../table.component';

export interface TableConfigurationData extends DialogData {
  columns: InternalTableColumn[];
  tableService: TableStorageService;
  translationKeyPrefix: string;
  uniqueTableName: string;
}

type FilterState = { [key: string]: unknown };

@Component({
  selector: 'th-table-configuration',
  templateUrl: './table-configuration.component.html',
  styleUrl: './table-configuration.component.scss',
})
export class TableConfigurationComponent
  implements IDialog<TableConfigurationData, FilterState>
{
  subject = new BehaviorSubject({});
  dialogData!: TableConfigurationData;
  @ViewChild('dialog') dialog!: DialogComponent;

  filterColumns(columnKey: string): void {
    this.dialogData.tableService.updateColumns(
      columnKey,
      this.dialogData.uniqueTableName,
    );
  }
}
