import { Component, Input } from '@angular/core';

@Component({
  selector: 'th-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent {
  @Input({ required: true }) text!: string;
  @Input({ required: true }) color!: string;
}
