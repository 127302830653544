import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize, from, map, switchMap } from 'rxjs';
import { environment } from '../../environment/environment';
import { SpinnerService } from '../services/spinner.service';
import { UserState } from '../state/user.state';

@Injectable()
export class TokenInjector implements HttpInterceptor {
  constructor(
    private userState: UserState,
    private spinnerService: SpinnerService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      [
        environment.coreApiUrl,
        environment.budgetApiUrl,
        environment.timeTrackingApiUrl,
      ].every((s) => !request.url.startsWith(s))
    ) {
      return next.handle(request);
    }

    let spinnerUsed = false;

    if (
      !request.url.endsWith('/salary-info') &&
      !request.url.endsWith('/dashboard/data')
    ) {
      this.spinnerService.incrementLoadingCounter();
      spinnerUsed = true;
    }

    const emulationToken = this.userState.getEmulationToken();
    return from(
      this.userState.getAuthorizationHeaderValue(
        request.url.endsWith('/user/token/refresh'),
      ),
    ).pipe(
      map((token) => {
        if (token) {
          return request.clone({
            setHeaders: {
              Authorization: token,
            },
          });
        }

        return request;
      }),
      map((request) => {
        if (emulationToken) {
          return request.clone({
            setHeaders: {
              'X-Emulation': emulationToken,
            },
          });
        }

        return request;
      }),
      switchMap((req) => next.handle(req)),
      finalize(
        () => spinnerUsed && this.spinnerService.decrementLoadingCounter(),
      ),
    );
  }
}
