/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ModelsUsedBudgetAreaPart { 
    budget?: number;
    budgetAreaId?: number;
    isForecast?: boolean;
    month?: number;
    usedPercentage?: number;
    usedSum?: number;
}

