/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Tag } from './tag.model';


export interface ModelsProjectEmployeeUpdate { 
    assignedWorkPercentage?: number;
    employeeId?: number;
    newAccountingUnitId?: number;
    oldAccountingUnitId?: number;
    reservedWorkPercentage?: number;
    status?: string;
    tags?: Array<Tag>;
    validFrom?: string;
}

