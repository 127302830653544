/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetDashboard } from './budget-dashboard.model';


export interface BudgetDashboardPreset { 
    createdAt?: string;
    dashboard?: BudgetDashboard;
    dashboardId?: number;
    entityId?: number;
    entityType?: string;
    id?: number;
    name?: string;
    updatedAt?: string;
    userId?: number;
}

