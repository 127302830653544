/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetBudgetAreaPart } from './budget-budget-area-part.model';
import { BudgetBudgetAreaExternalToolEntity } from './budget-budget-area-external-tool-entity.model';
import { BudgetBudgetAreaInternalEntity } from './budget-budget-area-internal-entity.model';


export interface BudgetBudgetArea { 
    budgetSum?: number;
    createdAt?: string;
    epics?: Array<BudgetBudgetAreaInternalEntity>;
    externalOrders?: Array<BudgetBudgetAreaExternalToolEntity>;
    externalProjects?: Array<BudgetBudgetAreaExternalToolEntity>;
    externalTasks?: Array<BudgetBudgetAreaExternalToolEntity>;
    fiscalYearId?: number;
    id?: number;
    name?: string;
    parts?: Array<BudgetBudgetAreaPart>;
    ticketSystems?: Array<BudgetBudgetAreaInternalEntity>;
    updatedAt?: string;
}

