/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UserInfo { 
    companyId: number;
    email: string;
    employeeId?: number;
    emulatedFirstName?: string;
    emulatedLastName?: string;
    emulatedRoles?: { [key: string]: string; };
    firstName: string;
    id: number;
    isEnabled: boolean;
    isLocked: boolean;
    lastName: string;
    projectIds: Array<number>;
    roles: { [key: string]: string; };
    wrongPasswordCounter: number;
}

