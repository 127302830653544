import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { UserState } from '../state/user.state';
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private userState: UserState,
    private router: Router,
    private roleService: RoleService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    await firstValueFrom(this.userState.initialize());
    if (this.userState.isAuthenticated) {
      if (state.url === '/login') {
        this.router.navigate(['']);
        return false;
      }

      const neededRoles = route.data['neededRoles'] ?? [];
      return this.roleService.isAuthorized(neededRoles);
    } else if (state.url === '/login') {
      return true;
    }

    this.userState.clearEmulationToken();
    this.router.navigate(['login']);
    return false;
  }
}
