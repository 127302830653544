import {
  distinctUntilChanged,
  MonoTypeOperatorFunction,
  Observable,
  shareReplay,
} from 'rxjs';
import { BaseState } from './base.state';
import { ChangeDefinition, Selector } from './types';

const loggingInactive = Symbol('state logging deactivated');

export function deactivateLogging(...objects: object[]): void {
  for (const obj of objects) {
    Object.defineProperty(obj, loggingInactive, {
      value: true,
      writable: false,
    });
  }
}

export function isLoggingDeactivated(item: object): boolean {
  return loggingInactive in item;
}

export function asSelector<T>(
  observable: Observable<T>,
  useImmer?: boolean,
  changeDef?: ChangeDefinition<T>,
): Selector<T> {
  const shared = useImmer ? observable.pipe(shareState(changeDef)) : observable;

  Object.defineProperty(shared, 'snapshot', {
    get() {
      let snapshot;
      shared.subscribe((val) => (snapshot = val)).unsubscribe();
      return snapshot;
    },
  });
  Object.defineProperty(shared, 'defineChange', {
    value: (cdef: ChangeDefinition<T>) =>
      asSelector(observable, useImmer, cdef),
  });

  return shared as Selector<T>;
}

export function shareState<T>(
  changeDef?: ChangeDefinition<T>,
): MonoTypeOperatorFunction<T> {
  let comparator: undefined | ((previous: T, current: T) => boolean);
  if (changeDef === 'deep')
    comparator = (x1: T, x2: T) => JSON.stringify(x1) === JSON.stringify(x2);
  else if (changeDef === 'shallow') comparator = shallowCompare;
  else comparator = changeDef;

  return (o: Observable<T>) =>
    o.pipe(
      distinctUntilChanged(comparator),
      shareReplay({ bufferSize: 0, refCount: true }),
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logActions<S extends BaseState<any>>(state: S): S {
  deactivateLogging(
    state['select'],
    state['updateState'],
    state['destroy'],
    state['derive'],
    state['asSelector'],
  );

  return new Proxy(state, {
    get(target: S, propertyKey: keyof S & string): unknown {
      const property: unknown = target[propertyKey];
      if (
        typeof property === 'function' &&
        !isLoggingDeactivated(property) &&
        target.constructor.prototype[propertyKey]
      ) {
        return new Proxy(property, {
          apply(fn, thisArg, argumentsList): void {
            console.log(
              `${target.constructor.name}.${String(propertyKey)}`,
              argumentsList,
            );
            return fn.apply(thisArg, argumentsList);
          },
        });
      }
      return property;
    },
  });
}

export function shallowCompare(a: unknown, b: unknown): boolean {
  // performant quickcheck
  if (a === b) return true;

  // case array
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length === b.length && a.every((val, i) => b[i] === val);
  }

  // case object
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    for (const key in a) {
      if (!(key in b) || a[key as never] !== b[key as never]) {
        return false;
      }
    }
    for (const key in b) {
      if (!(key in a)) {
        return false;
      }
    }
    return true;
  }

  // fallback comparison
  return a === b;
}
