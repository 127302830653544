import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BudgetAreaReporting } from '../../../budget/project/models/budget-area-reporting.model';
import { BudgetAreaReportingPart } from '../../../budget/project/models/budget-area-reporting-part.model';

@Component({
  selector: 'th-project-budget-reporting-tooltip',
  templateUrl: './project-budget-reporting-tooltip.component.html',
  styleUrls: ['./project-budget-reporting-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectBudgetReportingTooltipComponent implements OnInit {
  @Input() budgetAreaReporting!: BudgetAreaReporting;
  @Input() month!: number;
  isVisible = false;

  part: BudgetAreaReportingPart | undefined;

  ngOnInit(): void {
    this.part = this.budgetAreaReporting?.parts.find(
      (part) => part.month === this.month,
    );
  }

  get getUsedBudgetLabelKey(): string {
    return this.part?.isForecast
      ? 'tooltip.project-budget-reporting.expected-budget-usage'
      : 'tooltip.project-budget-reporting.used-budget';
  }
}
