<button
  type="button"
  [class.small]="small"
  class="{{ type }} w-full"
  [disabled]="isDisabled"
  [class.no-style]="noStyling"
>
  <span
    *ngIf="matIcon"
    class="text-inherit material-symbols-outlined text-xl leading-none"
    [class.mr-2]="text"
    [class.no-style]="noStyling"
    >{{ matIcon }}</span
  >
  {{ text }}
</button>
