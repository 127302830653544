<th-card-layout layout="one-column">
  <th-card-layout-box [title]="'emulation.open-requests' | translate">
    <div class="grid gap-5 items-center">
      <div class="font-bold">{{ 'emulation.admin-name' | translate }}</div>
      <div class="font-bold">{{ 'emulation.admin-email' | translate }}</div>
      <div class="font-bold">{{ 'emulation.company' | translate }}</div>
      <div class="font-bold">{{ 'emulation.creation-date' | translate }}</div>
      <div></div>
      <ng-container *ngFor="let request of requests">
        <div>{{ $any(request?.user) | employeeName }}</div>
        <div>{{ request?.user?.email }}</div>
        <div>{{ $any(request?.user?.companyId) | companyName | async }}</div>
        <div>{{ request?.createdAt | date: 'dd.MM.yyyy HH:mm' }}</div>
        <div class="flex">
          <th-button
            matIcon="delete"
            [noStyling]="true"
            (click)="deleteRequest(request)"
          ></th-button>
          <th-button
            matIcon="check"
            [noStyling]="true"
            (click)="confirmRequest(request)"
          ></th-button>
        </div>
      </ng-container>
      <ng-container *ngIf="!requests.length">
        <div class="col-span-5 my-5 text-center">
          {{ 'emulation.no-requests' | translate }}
        </div>
      </ng-container>
    </div>
  </th-card-layout-box>
</th-card-layout>
