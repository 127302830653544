/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetExternalToolEntity } from './budget-external-tool-entity.model';
import { ModelsTicketSystemViewModel } from './models-ticket-system-view-model.model';
import { Tag } from './tag.model';
import { BudgetFiscalYear } from './budget-fiscal-year.model';


export interface ModelsProjectViewModel { 
    aggregateData?: boolean;
    budgetPortion?: number;
    companyId?: number;
    externalToolOrders?: Array<BudgetExternalToolEntity>;
    externalToolProjects?: Array<BudgetExternalToolEntity>;
    fiscalYearEndDate?: string;
    fiscalYearStartDate?: string;
    fiscalYears?: Array<BudgetFiscalYear>;
    hideFromCompanyDashboard?: boolean;
    id?: number;
    importAccountingUnits?: boolean;
    importInvoices?: boolean;
    importTimes?: boolean;
    includedEmployees?: Array<string>;
    isActive?: boolean;
    isBudgetCarryOverAllowed?: boolean;
    name?: string;
    parentProjectId?: number;
    projectType?: string;
    subProjects?: Array<ModelsProjectViewModel>;
    syncTasks?: boolean;
    tags?: Array<Tag>;
    ticketSystems?: Array<ModelsTicketSystemViewModel>;
}

