import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormatOperations } from '../operations/number-format.operations';

@Pipe({ name: 'numberFormat' })
export class NumberFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(
    originalValue: number | string | undefined | null,
    shortNumber = false,
    twoDecimals = false,
  ): string | number | null {
    return NumberFormatOperations.format(
      this.decimalPipe,
      originalValue,
      shortNumber,
      twoDecimals,
    );
  }
}
