/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CoreUpdateUser } from './core-update-user.model';


export interface CreateUserResponse { 
    link?: string;
    user?: CoreUpdateUser;
}

