import { Component, Input } from '@angular/core';
import { Employee } from '../../budget/project/models/employee.model';

@Component({
  selector: 'th-employee-widget',
  templateUrl: './employee-widget.component.html',
  styleUrls: ['./employee-widget.component.scss'],
})
export class EmployeeWidgetComponent {
  @Input() employee!: Employee | null;
  @Input() showEmployeeInfo = true;

  public get initials(): string {
    return [this.employee?.firstName?.[0], this.employee?.lastName?.[0]]
      .filter((s) => !!s)
      .map((s) => s?.toUpperCase())
      .join('');
  }

  public get picture(): string {
    //return 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';
    return '';
  }
}
