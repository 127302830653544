import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'boolean',
})
export class BooleanPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: boolean): string {
    return this.translate.instant(value ? 'general.yes' : 'general.no');
  }
}
