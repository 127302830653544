import { Pipe, PipeTransform } from '@angular/core';
import { Employee } from '../../budget/project/models/employee.model';
import { UserInfo } from '../models/user-info.model';

@Pipe({
  name: 'employeeName',
})
export class EmployeeNamePipe implements PipeTransform {
  transform(employee: Employee | UserInfo | null): string {
    if (!employee) {
      return '';
    }

    return `${employee.firstName} ${employee.lastName}`.trim();
  }
}
