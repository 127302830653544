/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BudgetProjectAccountingUnit { 
    createdAt?: string;
    /**
     * TODO: ExternalRef Type
     */
    externalOrderRef?: string;
    id?: number;
    isDefault?: boolean;
    name?: string;
    price?: number;
    projectId?: number;
    /**
     * TODO: ExternalRef Type
     */
    replacedWithId?: number;
    unit?: string;
    updatedAt?: string;
    validFrom?: string;
}

