import { Injectable } from '@angular/core';
import { Observable, filter, map, of } from 'rxjs';
import { CompanyMgmtStateService } from '../company-mgmt/state/company-mgmt.state';
import { EmployeeState } from '../employee-mgmt/state/employee.state';
import { UserState } from '../user-mgmt/state/user.state';

@Injectable({
  providedIn: 'root',
})
export class TownhallNameResolverService {
  constructor(
    private companyState: CompanyMgmtStateService,
    private employeeState: EmployeeState,
    private userState: UserState,
  ) {}

  public resolveName(type: string, id: number): Observable<string> {
    const newKey = 'general.new';

    if (type === 'companies') {
      return this.companyState.company$.pipe(
        filter((company) => !!company),
        map((company) => company.name || newKey),
      );
    } else if (type === 'employees') {
      return this.employeeState.currentEmployee$.pipe(
        filter((employee) => !!employee),
        map(
          (employee) =>
            `${employee.firstName} ${employee.lastName}`.trim() || newKey,
        ),
      );
    } else if (type === 'users') {
      return this.userState.currentUser$.pipe(
        filter((user) => !!user),
        map((user) => {
          if (user.id === 0) {
            return newKey;
          }
          return `${user.firstName} ${user.lastName}`.trim();
        }),
      );
    }

    return of(id.toString());
  }
}
