<th-dialog
  (confirm)="confirm()"
  (cancel)="reset()"
  [dialogData]="dialogData"
  [hasSpacer]="true"
  [customWidth]="'w-96'"
  #dialog
>
  <div
    class="flex flex-col gap-4 items-center my-5 max-h-96 overflow-auto px-2"
  >
    <div
      *ngFor="let filter of filters; let last = last"
      class="w-full pl-0.0625 pr-0.0625 last:pb-1"
    >
      <label>{{
        (dialogData.translationKeyPrefix
          ? dialogData.translationKeyPrefix + '.' + filter.column.label
          : $any(filter.column.label)
        ) | translate
      }}</label>
      <ng-container [ngSwitch]="filter?.column?.filterType">
        <th-dropdown
          *ngSwitchCase="'dropdown'"
          [items]="$any(filter.fieldValues)"
          [(value)]="filter.selectedFilterValues"
          [isMultiple]="true"
        ></th-dropdown>
        <th-input
          *ngSwitchDefault
          [(value)]="filter.selectedFilterValues"
          [isDisabled]="filter.isIntermediate"
        ></th-input>
      </ng-container>
    </div>
  </div>
</th-dialog>
