import { Component, Input } from '@angular/core';
import { RouteNameResolver } from '../services/navigation.service';

@Component({
  selector: 'th-page-head',
  templateUrl: './page-head.component.html',
  styleUrls: ['./page-head.component.scss'],
})
export class PageHeadComponent {
  @Input({ required: true }) nameResolver!: RouteNameResolver;
}
