import { Pipe, PipeTransform } from '@angular/core';
import { DurationOperations } from '../operations/duration.operations';

@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(
    originalValue: number | undefined,
    sourceUnit: 'h' | 'm' | 's' | 'ms' = 'ms',
    targetUnits = 'd,h,m',
  ): string {
    return DurationOperations.format(originalValue, sourceUnit, targetUnits);
  }
}
