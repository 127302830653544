import { Component, Input } from '@angular/core';
import { HelperService } from '../services/helper.service';

@Component({
  selector: 'th-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent {
  @Input() text = '';
  @Input() type: 'info' | 'warning' | undefined;
  @Input() isDisabled = false;

  get icon(): string {
    switch (this.type) {
      case 'info':
        return 'info';
      case 'warning':
        return 'warning';
      case undefined:
        return 'info';
    }
  }

  constructor(public helperService: HelperService) {}
}
