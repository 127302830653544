/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetEmployee } from './budget-employee.model';
import { BudgetProjectEmployeeSalaryType } from './budget-project-employee-salary-type.model';


export interface BudgetProjectEmployee { 
    assignedWorkPercentage?: number;
    createdAt?: string;
    employee?: BudgetEmployee;
    employeeId?: number;
    id?: number;
    projectEmployeeSalaryTypes?: Array<BudgetProjectEmployeeSalaryType>;
    projectId?: number;
    reservedWorkPercentage?: number;
    status?: string;
    updatedAt?: string;
}

