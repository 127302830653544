import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { theme } from 'tailwindcss/defaultConfig';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private mediaWidthCollapseThreshold = 1024;
  private mediaWidthMobileSize = 768;

  private readonly screenSizeSubjects = {
    isSmallScreen: new BehaviorSubject<boolean>(false),
    isMobile: new BehaviorSubject<boolean>(false),
    isSidebarCollapseThreshold: new BehaviorSubject<boolean>(false),
    isSmallBreadcrumb: new BehaviorSubject<boolean>(false),
  };

  isSmallScreen$ = this.screenSizeSubjects.isSmallScreen.asObservable();
  isMobile$ = this.screenSizeSubjects.isMobile.asObservable();
  isSidebarCollapseThreshold$ =
    this.screenSizeSubjects.isSidebarCollapseThreshold.asObservable();
  isSmallBreadcrumb$ = this.screenSizeSubjects.isSmallBreadcrumb.asObservable();

  constructor() {
    this.initializeBreakpoints();
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize.bind(this));
  }

  private initializeBreakpoints(): void {
    if (theme?.screens) {
      const mediaBreakPoints = Object.entries(theme.screens);
      this.mediaWidthCollapseThreshold =
        this.getBreakpoint(mediaBreakPoints, 'lg') ??
        this.mediaWidthCollapseThreshold;
      this.mediaWidthMobileSize =
        this.getBreakpoint(mediaBreakPoints, 'md') ?? this.mediaWidthMobileSize;
    }
  }

  private getBreakpoint(
    breakpoints: [string, string][],
    key: string,
  ): number | null {
    const breakpoint = breakpoints.find(([name]) => name === key);
    return breakpoint ? parseInt(breakpoint[1]) : null;
  }

  private checkScreenSize(): void {
    const { innerWidth } = window;

    this.screenSizeSubjects.isSidebarCollapseThreshold.next(
      innerWidth <= this.mediaWidthCollapseThreshold,
    );
    this.screenSizeSubjects.isMobile.next(
      innerWidth <= this.mediaWidthMobileSize,
    );
    this.screenSizeSubjects.isSmallScreen.next(innerWidth <= 1260);
    this.screenSizeSubjects.isSmallBreadcrumb.next(innerWidth <= 400);
  }
}
