/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetSprint } from './budget-sprint.model';


export interface ModelsSprintInformation { 
    passedDays?: number;
    sprint?: BudgetSprint;
    totalDays?: number;
}

