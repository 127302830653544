/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetSprintEmployee } from './budget-sprint-employee.model';


export interface ModelsSprintCalculationRequest { 
    employees?: Array<BudgetSprintEmployee>;
    endDate?: string;
    startDate?: string;
}

