import { Project } from '../../budget/project/models/project.model';

export class FiscalYearOperations {
  public static getFiscalYearMonths(project: Project): number[] {
    const startMonth = new Date(project.fiscalYearStartDate).getUTCMonth();
    const endMonth = new Date(project.fiscalYearEndDate).getUTCMonth();
    const monthIndices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    let fyMonths = monthIndices.slice(
      startMonth,
      endMonth <= monthIndices[-1] ? endMonth : undefined,
    );

    if (endMonth <= startMonth) {
      fyMonths = [...fyMonths, ...monthIndices.slice(0, endMonth + 1)];
    }

    return fyMonths;
  }

}
