import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pure' })
export class PureFunctionPipe implements PipeTransform {
  transform(
    value: Parameters<any>[0],
    func: (...args: any) => any,
    ...args: any
  ): any {
    return func(value, ...args);
  }
}
