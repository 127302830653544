/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CoreCompany { 
    createdAt?: string;
    domain?: string;
    externalRefs?: { [key: string]: string; };
    id?: number;
    /**
     * TODO: Choose external-tool to interact with
     */
    importEmployees?: boolean;
    /**
     * TODO: Choose external-tool to interact with
     */
    isProjectTimetrackingActive?: boolean;
    name?: string;
    parentCompany?: CoreCompany;
    parentCompanyId?: number;
    updatedAt?: string;
}

