/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BudgetEmployee { 
    companyId?: number;
    createdAt?: string;
    email?: string;
    entryDate?: string;
    exitDate?: string;
    firstName?: string;
    headEmployeeId?: number;
    id?: number;
    isProductive?: boolean;
    lastName?: string;
    status?: string;
    updatedAt?: string;
    weeklyWorkingHours?: number;
}

