/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetExternalToolEntity } from './budget-external-tool-entity.model';
import { BudgetTicketSystem } from './budget-ticket-system.model';
import { BudgetFiscalYear } from './budget-fiscal-year.model';
import { CoreCompany } from './core-company.model';


export interface BudgetProject { 
    aggregateData?: boolean;
    budgetPortion?: number;
    company?: CoreCompany;
    companyId?: number;
    createdAt?: string;
    externalToolOrders?: Array<BudgetExternalToolEntity>;
    externalToolProjects?: Array<BudgetExternalToolEntity>;
    fiscalYearEndDate?: string;
    fiscalYearStartDate?: string;
    fiscalYears?: Array<BudgetFiscalYear>;
    hideFromCompanyDashboard?: boolean;
    id?: number;
    /**
     * TODO: Choose external-tool to interact with
     */
    importAccountingUnits?: boolean;
    /**
     * TODO: Choose external-tool to interact with
     */
    importInvoices?: boolean;
    /**
     * TODO: Choose external-tool to interact with
     */
    importTimes?: boolean;
    includedEmployees?: Array<string>;
    isActive?: boolean;
    name?: string;
    parentProject?: BudgetProject;
    parentProjectId?: number;
    projectType?: string;
    subProjects?: Array<BudgetProject>;
    /**
     * TODO: Choose external-tool to interact with
     */
    syncTasks?: boolean;
    ticketSystems?: Array<BudgetTicketSystem>;
    updatedAt?: string;
}

