/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModelsDailyAttendanceOverview } from './models-daily-attendance-overview.model';


export interface ModelsMonthlyAttendanceOverview { 
    dailyAttendanceOverview?: Array<ModelsDailyAttendanceOverview>;
    date?: string;
    employeeId?: number;
}

