import { ModuleWithProviders, NgModule } from '@angular/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthClientConfig } from './models/auth-client-config.model';
import {
  AuthAzureConfigService,
  AuthService,
  AuthTownhallConfigService,
} from './services/auth.service';

@NgModule({
  imports: [OAuthModule.forRoot()],
  providers: [
    { provide: OAuthStorage, useValue: localStorage }
  ],
  declarations: [AuthCallbackComponent],
  exports: [AuthCallbackComponent],
})
export class AuthModule {
  static forRoot(
    azureConfig: AuthClientConfig,
    townhallConfig: AuthClientConfig,
  ): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        {
          provide: AuthAzureConfigService,
          useValue: azureConfig,
        },
        {
          provide: AuthTownhallConfigService,
          useValue: townhallConfig,
        },
      ],
    };
  }
}
