/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetBudgetAreaExternalToolEntity } from './budget-budget-area-external-tool-entity.model';
import { BudgetBudgetAreaInternalEntity } from './budget-budget-area-internal-entity.model';


export interface ModelsBudgetAreaAssignmentSaveModel { 
    externals?: Array<BudgetBudgetAreaExternalToolEntity>;
    internals?: Array<BudgetBudgetAreaInternalEntity>;
}

