/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetProject } from './budget-project.model';


export interface BudgetFiscalYear { 
    budgetSum?: number;
    createdAt?: string;
    endDate?: string;
    id?: number;
    name?: string;
    project?: BudgetProject;
    projectId?: number;
    startDate?: string;
    updatedAt?: string;
}

