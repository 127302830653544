import {
  ApplicationRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  debounceTime,
  filter,
  skip,
  tap,
} from 'rxjs';
import { DialogComponent } from '../dialog/dialog.component';
import { DropdownItem } from '../dropdown/dropwdown-item.model';
import { ExternalRefs } from '../models/external-refs.model';
import { ExternalToolItem } from '../models/external-tool-item.model';
import { HelperService } from '../services/helper.service';
import { AssignmentInputDialogComponent } from './dialog/assignment-input-dialog.component';

@Component({
  selector: 'th-assignment-input',
  templateUrl: './assignment-input.component.html',
  styleUrls: ['./assignment-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssignmentInputComponent),
      multi: true,
    },
  ],
})
export class AssignmentInputComponent
  implements OnDestroy, OnInit, ControlValueAccessor
{
  private _value = new BehaviorSubject<ExternalToolItem | null>(null);
  private valueSubscription!: Subscription;
  private items!: ExternalToolItem[];
  public isInitialized = false;

  @Input()
  get value(): ExternalRefs | null {
    return this._value?.value
      ? { [this._value?.value.toolName]: this._value?.value.item.id }
      : null;
  }
  set value(value: ExternalRefs | null) {
    if (
      this._value.value?.toolName &&
      this._value.value?.toolName !== 'undefined' &&
      this._value.value?.item?.id ===
        value?.[this._value.value?.toolName as string]
    ) {
      return;
    }
    this._value.next(this.getItem(value));
  }

  @Input({ required: true }) items$!: Observable<ExternalToolItem[]>;
  @Input() lazyLoadItems!: (page: number) => Observable<DropdownItem[]>;
  @Input() labelText = '';
  @Input() placeholder = '';
  @Input() isDisabled = false;
  @Input() tooltipText? = '';
  @Input() noMargin = false;
  @Input() debounce = 0;
  @Input() emitInitialValueChange = true;
  @Output() valueChange = new EventEmitter<ExternalRefs | null>();
  @Output() accept = new EventEmitter<ExternalToolItem | null>();

  get selectedName(): string {
    return this._value.value?.item?.name ?? '';
  }

  constructor(
    public helperService: HelperService,
    private applicationRef: ApplicationRef,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {};

  writeValue(value: ExternalRefs): void {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  ngOnInit(): void {
    this.items$
      .pipe(
        tap((items) => (this.items = items)),
        tap(() =>
          this._value.next(
            this.getItem({
              [this._value.value?.toolName as string]: this._value.value?.item
                .id as string,
            }),
          ),
        ),
        tap(() => {
          if (!this.isInitialized && this.emitInitialValueChange) {
            this.valueChange.emit(this.value);
          }

          this.isInitialized = true;
          this._value.next(this.getItem(this.value));
          this.initValueSubscription();
        }),
      )
      .subscribe();
  }

  private initValueSubscription(): void {
    this.valueSubscription = this._value
      .pipe(
        debounceTime(this.debounce),
        skip(1),
        tap((v) =>
          this.valueChange.emit({
            [v?.toolName as string]: v?.item.id as string,
          }),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.valueSubscription?.unsubscribe();
  }

  private getItem(
    item: ExternalRefs | null | undefined,
  ): ExternalToolItem | null {
    const x = this.items?.find((i) => i.item.id === item?.[i.toolName]);
    if (!x) {
      if (item) {
        return {
          item: {
            id: item[Object.keys(item)[0]],
            name: item[Object.keys(item)[0]],
          },
          toolName: Object.keys(item)[0] ?? '',
        };
      } else {
        return null;
      }
    }

    return x ?? null;
  }

  public openDialog(): void {
    DialogComponent.show(AssignmentInputDialogComponent, this.applicationRef, {
      title: 'project.choose-external-item',
      type: 'question',
      items: this.items,
      lazyLoadItems: this.lazyLoadItems,
    })
      .pipe(
        filter((result) => !!result),
        tap((result) => {
          this.value = {
            [result.item?.toolName as string]: result.item?.item.id as string,
          };
          this.onChange(this.value);
        }),
        tap((result) => this.accept.emit(result.item)),
      )
      .subscribe();
  }
}
