/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmployeeSalaryInfo } from './employee-salary-info.model';


export interface TownhallcorePageableEmployeeSalaryInfo { 
    content?: Array<EmployeeSalaryInfo>;
    hasMore?: boolean;
    page?: number;
    size?: number;
    totalSize?: number;
}

