/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModelsCapacityPlanningViewModel } from './models-capacity-planning-view-model.model';


export interface TownhallcorePageableModelsCapacityPlanningViewModel { 
    content?: Array<ModelsCapacityPlanningViewModel>;
    hasMore?: boolean;
    page?: number;
    size?: number;
    totalSize?: number;
}

