import { Component, Input } from '@angular/core';

@Component({
  selector: 'th-counter-circle',
  templateUrl: './counter-circle.component.html',
  styleUrls: ['./counter-circle.component.scss'],
})
export class CounterCircleComponent {
  @Input() value?: string;
}
