import * as humanizeDuration from 'humanize-duration';

export class DurationOperations {
  public static format(
    originalValue: number | undefined,
    sourceUnit: 'h' | 'm' | 's' | 'ms' = 'ms',
    targetUnits = 'd,h,m',
  ): string {
    if (!originalValue) {
      return '0';
    }

    let value = originalValue;
    if (sourceUnit === 's') {
      value = originalValue * 1000;
    } else if (sourceUnit === 'm') {
      value = originalValue * 60 * 1000;
    } else {
      value = originalValue * 60 * 60 * 1000;
    }

    const formatted = humanizeDuration(value, {
      units: targetUnits.split(',') as humanizeDuration.Unit[],
      maxDecimalPoints: 2,
      round: true,
      language: 'de',
    });
    return `${originalValue < 0 ? '- ' : ''}${formatted}`;
  }
}
