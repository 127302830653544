/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetCapacityPlanningTargetEffort } from './budget-capacity-planning-target-effort.model';
import { ModelsCapacityPlanningEmployeeViewModel } from './models-capacity-planning-employee-view-model.model';


export interface ModelsCapacityPlanningViewModel { 
    capacityPlanningEmployees?: Array<ModelsCapacityPlanningEmployeeViewModel>;
    capacityPlanningTargetEfforts?: Array<BudgetCapacityPlanningTargetEffort>;
    employeeCount?: number;
    endDate?: string;
    id?: number;
    name?: string;
    startDate?: string;
    totalHours?: number;
}

