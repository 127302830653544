/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ModelsEmployeeProjectTimeViewModel { 
    comment?: string;
    employeeId: number;
    externalProjectRef: string;
    externalTaskRef: string;
    hours: number;
    id: number;
    projectName?: string;
    taskName?: string;
    timestamp: string;
}

