import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private timeout = 5000; // ms
  private loadingCounter$ = new BehaviorSubject<number>(0);
  private timeoutHandler: any = null;
  public shouldLoad$ = this.loadingCounter$.pipe(
    map((count) => count > 0),
    tap(() => this.present()),
    distinctUntilChanged(),
    tap((isLoading) => {
      if (isLoading) {
        this.timeoutHandler = setTimeout(() => {
          this.loadingCounter$.next(0);
        }, this.timeout);
      } else {
        if (this.timeoutHandler) {
          clearTimeout(this.timeoutHandler);
        }
      }
    }),
  ) as Observable<any>;

  constructor(private spinner: NgxSpinnerService) {
    this.spinner.show('th-spinner');
  }

  public incrementLoadingCounter(): void {
    this.loadingCounter$.next(this.loadingCounter$.value + 1);
  }

  public decrementLoadingCounter(): void {
    const currentCount = this.loadingCounter$.value;
    if (currentCount > 0) {
      this.loadingCounter$.next(currentCount - 1);
    }
  }

  public dismiss(): void {
    this.loadingCounter$.next(0);
    this.spinner.hide('th-spinner');
  }

  public present(): void {
    this.spinner.show('th-spinner');
  }
}
