import { DestroyRef } from '@angular/core';
import { Observable } from 'rxjs';
import { share, shareReplay } from 'rxjs/operators';

/**
 * Once added, an Observable's result can be used multiple times in multiple places.
 *
 * A network request for example does complete once for every subscription.
 * The same Observable can be added to the cache and then retrieved and subscribed to multiple times always returning the last emitted value of the source Observable.
 */
export class ObservableCachingHelper<T> {
  private cache: Map<string, Observable<T>> = new Map();

  /**
   * @param destroyRef registers the onDestroy callback on the reference
   * @param divider will be set in between key parts forming the cachekey (should be some char sequence not contained in any key part)
   */
  constructor(
    private destroyRef: DestroyRef,
    private divider = '///',
  ) {
    this.destroyRef.onDestroy(() => this.onDestroy());
  }

  onDestroy(): void {
    this.cache.clear();
  }

  /**
   * Returns the cached multicast Observable
   */
  public cacheGet$(keyParts: string[]): Observable<T> | undefined {
    return this.cache.get(this.combineKey(keyParts));
  }

  /**
   * Adds an Observable to the cache
   *
   * @param value the Observable
   * @param keyParts the cache key
   * @param replay whether to cache the value permanently
   * @returns the added multicast Observable
   */
  public cacheSet$(
    value: Observable<T>,
    keyParts: string[],
    replay = false,
  ): Observable<T> {
    const dispatcher = value.pipe(
      replay ? shareReplay({ bufferSize: 1, refCount: false }) : share(),
    );
    this.cache.set(this.combineKey(keyParts), dispatcher);
    return dispatcher;
  }

  /**
   * Like setCachedValue$ but first checks if the cache key already exists
   *
   * @param value the Observable
   * @param keyParts the cache key
   * @param replay  whether to cache the value permanently
   * @returns the cached (if found) or added multicast Observable
   */
  public cacheSetOnMiss$(
    value: Observable<T>,
    keyParts: string[],
    replay = false,
  ): Observable<T> {
    const dispatcher = this.cacheGet$(keyParts);
    if (dispatcher) {
      return dispatcher;
    }
    return this.cacheSet$(value, keyParts, replay);
  }

  /**
   * Removes the Observable from the cache
   *
   * @param keyParts the cache key
   * @returns the cached but now removed multicast Observable
   */
  public cacheRemove$(keyParts: string[]): Observable<T> | undefined {
    const dispatcher = this.cacheGet$(keyParts);
    if (dispatcher) {
      this.cache.delete(this.combineKey(keyParts));
    }
    return dispatcher;
  }

  private combineKey(key: string[]): string {
    if (!key.length) {
      throw new Error('No key specified');
    }

    return key.join(this.divider);
  }
}
