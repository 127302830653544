/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RepositoriesWidgetSettings { 
    borderless?: boolean;
    displayTitle?: boolean;
    height?: number;
    positionX?: number;
    positionY?: number;
    width?: number;
}

