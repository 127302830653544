import { DecimalPipe } from '@angular/common';
import { RoundOperations } from './round.operations';

export class NumberFormatOperations {
  public static format(
    decimalPipe: DecimalPipe,
    originalValue: number | string | undefined | null,
    shortNumber = false,
    twoDecimals = false,
  ): string | number | null {
    if (!originalValue) {
      return '0';
    }

    if (typeof originalValue === 'string') {
      return originalValue;
    }

    let value = originalValue;

    if (shortNumber) {
      value = this.shorten(value);
    } else {
      value = RoundOperations.round(value, 2);
    }

    const format = twoDecimals ? '1.2-2' : '1.0-0';
    let result = decimalPipe.transform(value, format);

    if (shortNumber) {
      result += this.getShortenText(originalValue);
    }

    return result;
  }

  private static shorten(value: number): number {
    if (RoundOperations.isOverMillion(value)) {
      return RoundOperations.round(value / 1_000_000, 2);
    } else if (RoundOperations.isOverThousand(value)) {
      return RoundOperations.round(value / 1_000, 1);
    }

    return RoundOperations.round(value, 2);
  }

  private static getShortenText(value: number): string {
    if (RoundOperations.isOverMillion(value)) {
      return ' Mio.';
    } else if (RoundOperations.isOverThousand(value)) {
      return 'k';
    }

    return '';
  }
}
