/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetDashboardItem } from './budget-dashboard-item.model';
import { User } from './user.model';


export interface BudgetDashboard { 
    createdAt?: string;
    id?: number;
    name?: string;
    type?: string;
    updatedAt?: string;
    user?: User;
    userId?: number;
    widgets?: Array<BudgetDashboardItem>;
}

