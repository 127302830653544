/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RepositoriesWidgetSettings } from './repositories-widget-settings.model';


export interface RepositoriesWidget { 
    data?: { [key: string]: object; };
    settings?: RepositoriesWidgetSettings;
    type?: string;
}

