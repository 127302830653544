/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetProjectAccountingUnit } from './budget-project-accounting-unit.model';


export interface BudgetProjectEmployeeSalaryType { 
    createdAt?: string;
    id?: number;
    projectAccountingUnit?: BudgetProjectAccountingUnit;
    projectAccountingUnitId?: number;
    projectEmployeeId?: number;
    updatedAt?: string;
    validFrom?: string;
}

