import { Component, Input } from '@angular/core';

@Component({
  selector: 'th-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() text = '';
  @Input() type:
    | 'error'
    | 'warning'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'gray'
    | 'transparent'
    | 'white'
    | 'primary-transparent' = 'primary';
  @Input() noStyling!: boolean;
  @Input() matIcon?: string;
  @Input() isDisabled = false;
  @Input() small = false;
}
