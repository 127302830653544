import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TownhallNameResolverService } from './services/townhall-name-resolver.service';
import { SpinnerService } from './shared/services/spinner.service';
import { ThemeService } from './shared/services/theme.service';
import { LicenseState } from './shared/state/license.state';

@Component({
  selector: 'th-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public get isGlobalSidebarVisible(): boolean {
    return ['budget', 'timetracking', 'login', 'callback'].every(
      (x) => !this.router.url.includes(x),
    );
  }

  constructor(
    translate: TranslateService,
    private router: Router,
    public themeService: ThemeService,
    public townhallNameResolver: TownhallNameResolverService,
    public spinnerService: SpinnerService,
    private licenseState: LicenseState,
  ) {
    translate.setDefaultLang('de');
    translate.use('de');
  }

  ngOnInit(): void {
    this.licenseState.initialize().subscribe();
  }
}
