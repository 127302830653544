import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { EmulationSession } from '../shared/models/emulation-session.model';
import { EmulationService } from '../shared/services/emulation.service';

@Component({
  selector: 'th-emulation-requests',
  templateUrl: './emulation-requests.component.html',
  styleUrls: ['./emulation-requests.component.scss'],
})
export class EmulationRequestsComponent implements OnInit {
  public requests: EmulationSession[] = [];

  constructor(
    private emulationService: EmulationService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.loadRequests();
  }

  private loadRequests(): void {
    this.emulationService
      .getOwnPendingSessionRequests()
      .pipe(
        tap((requests) => (this.requests = requests)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  confirmRequest(request: EmulationSession): void {
    this.emulationService
      .confirmEmulationSession(request.userId, request.targetUserId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.loadRequests()),
      )
      .subscribe();
  }

  deleteRequest(request: EmulationSession): void {
    this.emulationService
      .deleteEmulationSession(request.userId, request.targetUserId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.loadRequests()),
      )
      .subscribe();
  }
}
