/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BudgetEmployee } from './budget-employee.model';
import { Tag } from './tag.model';


export interface ModelsProjectEmployeeViewModels { 
    employee?: BudgetEmployee;
    tags?: Array<Tag>;
}

