<th-dialog
  (confirm)="confirm()"
  (cancel)="cancel()"
  [dialogData]="dialogData"
  #dialog
>
  <p class="text-sm">
    {{ dialogData.description | translate }}
  </p>
</th-dialog>
