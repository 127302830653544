/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CoreCompanyViewModel { 
    domain?: string;
    employeeCount?: number;
    externalRefs?: { [key: string]: string; };
    id?: number;
    importEmployees?: boolean;
    name?: string;
    parentCompanyId?: number;
}

