import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { LicenseInfo } from '../models/license-info.model';
import { LicenseService } from '../services/license.service';
import { BaseState } from './base.state';

interface LicenseModel {
  licenseInfo: LicenseInfo | null;
}

const AllFeatures = 'All';

@Injectable({
  providedIn: 'root',
})
export class LicenseState extends BaseState<LicenseModel> {
  constructor(private licenseService: LicenseService) {
    super(
      {
        licenseInfo: null,
      },
      { useImmer: true },
    );
  }

  public licenseInfo$ = this.select(({ licenseInfo }) => licenseInfo);

  public isFeatureLicensed(feature: string): boolean {
    return (
      (feature === AllFeatures ||
        this.selectSnapshot(
          ({ licenseInfo }) => licenseInfo,
        )?.includedFeatures.includes(feature)) ??
      false
    );
  }

  public initialize(): Observable<LicenseInfo | null> {
    if (!this.selectSnapshot(({ licenseInfo }) => licenseInfo)) {
      return this.licenseService.getLicenseInfo().pipe(
        tap((info) =>
          this.updateState((state) => {
            state.licenseInfo = info;
          }),
        ),
        catchError(() => {
          this.updateState((state) => {
            state.licenseInfo = null;
          });
          return of(null);
        }),
      );
    }

    return of(null);
  }
}
