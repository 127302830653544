<div
  aria-live="assertive"
  class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
  [class]="toastClasses"
>
  <div class="flex w-full flex-col items-center space-y-4">
    <div
      class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg card"
    >
      <div class="p-4">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <span class="material-symbols-outlined">{{ icon }}</span>
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p
              *ngIf="title"
              class="text-base font-bold"
              [class]="options.titleClass"
              [attr.aria-label]="title"
            >
              {{ title }}
            </p>
            <p
              *ngIf="message && options.enableHtml"
              class="text-sm"
              [class]="options.messageClass"
              [innerHTML]="message"
            ></p>
            <p
              *ngIf="message && !options.enableHtml"
              class="text-sm"
              [class]="options.messageClass"
              [attr.aria-label]="message"
            >
              {{ message }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
