/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BudgetBudgetAreaExternalToolEntity { 
    budgetAreaId?: number;
    createdAt?: string;
    entityType?: string;
    externalToolRef?: { [key: string]: string; };
    id?: number;
    updatedAt?: string;
}

