/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModelsUsedBudgetArea } from './models-used-budget-area.model';


export interface ModelsBudgetAreaReport { 
    areas?: Array<ModelsUsedBudgetArea>;
    budgetSum?: number;
    fiscalYearId?: number;
}

