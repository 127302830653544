/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ModelsSprintAreaOverview { 
    employees?: Array<string>;
    id?: number;
    name?: string;
    plannedCosts?: number;
    plannedHours?: number;
    usedCosts?: number;
    usedHours?: number;
}

