/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User } from './user.model';


export interface BudgetEmulationSession { 
    createdAt?: string;
    expiresAt?: string;
    id?: number;
    isConfirmed?: boolean;
    targetUser?: User;
    targetUserId?: number;
    updatedAt?: string;
    user?: User;
    userId?: number;
}

