export const environment = {
  coreApiUrl: 'https://dev.api.townhall.nerdware.dev/core',
  budgetApiUrl: 'https://dev.api.townhall.nerdware.dev/budget',
  timeTrackingApiUrl: 'https://dev.api.townhall.nerdware.dev/timetracking',
  townhallAuth: {
    clientId: 'townhall-client',
    issuer: 'https://api.townhall.nerdware.dev',
    scopes: 'openid profile email',
  },
  azureAuth: {
    clientId: '5f6b0034-e2f3-4c8e-a6e5-94de8c6f78ac',
    issuer:
      'https://login.microsoftonline.com/db3edb01-d0fe-476d-9e08-c63329eeb6da/v2.0',
    scopes: 'openid profile',
  },
};
