<div class="base-container">
  <div class="flex mb-5 items-center">
    <div class="grow flex gap-3 items-center">
      <div class="flex gap-3 items-center">
        <p class="font-bold">
          {{ 'project.budgetArea.budget-sum' | translate }}:
        </p>
        <p class="font-bold">{{ budgetSum | numberFormat: true : true }} €</p>
      </div>
    </div>
  </div>
  <th-table
    *ngIf="tableConfig"
    class="base-container"
    [config]="tableConfig"
    [rowTemplate]="rowTemplate"
    [preRowsTemplate]="preRowsTemplate"
    [dataItems$]="areas$"
    [isColumnFilteringEnabled]="false"
    [isTableHeaderCentered]="true"
  ></th-table>
  <ng-template plate #preRowsTemplate let-rows>
    <tr class="font-bold">
      <td></td>
      <td
        *ngFor="let month of fyMonths"
        [tp]="'reporting.planned-budget' | translate"
        class="text-center"
      >
        {{ rows | pure: monthFullBudget : month | numberFormat: false : true }}
        €
      </td>
      <td></td>
    </tr>
    <tr class="font-bold">
      <td></td>
      <td
        *ngFor="let month of fyMonths"
        [tp]="'reporting.used-budget' | translate"
        class="text-center"
      >
        {{
          rows | pure: monthFullUsedBudget : month | numberFormat: false : true
        }}
        €
      </td>
      <td></td>
    </tr>
  </ng-template>
  <ng-template #rowTemplate let-row let-columns="columns">
    <td class="!py-0">
      <div class="flex flex-col items-start">
        <span class="flex justify-between w-full py-2.5 items-center">
          <span
            [class.hover:underline]="!hideTicketLink"
            [class.cursor-pointer]="!hideTicketLink"
            (click)="hideTicketLink ? null : openTickets(row)"
            >{{ row?.name | translate }}</span
          >
          <span
            class="icon material-symbols-outlined cursor-pointer"
            (click)="toggleBudgetAreaExpansion(row)"
          >
            {{ isAreaExpanded(row) ? 'expand_more' : 'chevron_right' }}
          </span>
        </span>

        <ng-container
          *ngIf="isAreaExpanded(row) && !(row | pure: isUnscopedRow)"
        >
          <span class="py-2.5 flex items-center">
            {{ 'reporting.planned-budget' | translate }}
          </span>
        </ng-container>
        <ng-container *ngIf="isAreaExpanded(row)">
          <span class="py-2.5 flex items-center">
            {{ 'reporting.used-budget' | translate }}
          </span>
        </ng-container>
        <ng-container
          *ngIf="isAreaExpanded(row) && !(row | pure: isUnscopedRow)"
        >
          <span class="py-2.5 flex items-center">
            {{ 'reporting.used-percentage' | translate }}
          </span>
        </ng-container>
      </div>
    </td>

    <ng-container *ngIf="!isAreaExpanded(row)">
      <td *ngFor="let month of fyMonths">
        <div class="flex items-center justify-center p-2.5 h-10"></div>
      </td>
    </ng-container>

    <ng-container *ngIf="isAreaExpanded(row)">
      <td
        *ngFor="let month of fyMonths"
        [attr.class]="getCellClass(row, month)"
      >
        <div class="flex items-center justify-center p-2.5 h-10"></div>

        <ng-container *ngIf="row | pure: isUnscopedRow">
          <div
            class="flex items-center justify-center p-2.5"
            [tp]="
              ('reporting.used-budget' | translate) +
              ': ' +
              (row
                | pure: monthUsedBudget : month
                | numberFormat: false : true) +
              ' €'
            "
          >
            <span
              [innerHTML]="
                row | pure: monthUsedBudget : month | numberFormat: false : true
              "
            ></span
            ><span> €</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!(row | pure: isUnscopedRow)">
          <div class="flex items-center justify-center p-2.5">
            <th-project-budget-reporting-tooltip
              [budgetAreaReporting]="row"
              [month]="month"
            >
              <span>{{
                row
                  | pure: monthPlannedBudget : month
                  | numberFormat: false : true
              }}</span
              ><span> €</span>
            </th-project-budget-reporting-tooltip>
          </div>
          <div class="flex items-center justify-center p-2.5">
            <th-project-budget-reporting-tooltip
              [budgetAreaReporting]="row"
              [month]="month"
            >
              <span
                >{{
                  row
                    | pure: monthUsedBudget : month
                    | numberFormat: false : true
                }} </span
              ><span> € {{ hasMonthForecast(row, month) ? '*' : '' }}</span>
            </th-project-budget-reporting-tooltip>
          </div>
          <div
            *ngIf="(row | pure: monthPercentage : month) !== 9999900"
            class="flex items-center justify-center p-2.5"
          >
            <th-project-budget-reporting-tooltip
              [budgetAreaReporting]="row"
              [month]="month"
            >
              <span
                [innerHTML]="row | pure: monthPercentage : month | numberFormat"
              ></span
              ><span> %</span>
            </th-project-budget-reporting-tooltip>
          </div>
          <div
            *ngIf="(row | pure: monthPercentage : month) === 9999900"
            class="flex items-center justify-center p-2.5 h-10"
          ></div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container *ngIf="!isAreaExpanded(row)">
      <td class="font-bold !py-0">
        <div class="flex items-center p-2.5 h-full">
          <span> {{ row?.usedSum | numberFormat: false : true }}</span
          ><span> €</span>
        </div>
      </td>
    </ng-container>

    <ng-container *ngIf="isAreaExpanded(row)">
      <td *ngIf="row | pure: isUnscopedRow" class="font-bold !py-0">
        <div class="p-2.5 h-10"></div>
        <div class="p-2.5" [tp]="'reporting.used-budget' | translate">
          <span>{{ row?.usedSum | numberFormat: false : true }}</span
          ><span> €</span>
        </div>
      </td>

      <td *ngIf="!(row | pure: isUnscopedRow)" class="font-bold !py-0">
        <div class="p-2.5 h-10"></div>

        <div
          class="p-2.5"
          [tp]="
            ('reporting.planned-budget' | translate) +
            ': ' +
            (row?.budgetSum | numberFormat: false : true) +
            ' €'
          "
        >
          <span>{{ row?.budgetSum | numberFormat: false : true }}</span
          ><span> €</span>
        </div>
        <div
          class="p-2.5"
          [tp]="
            ('reporting.used-budget' | translate) +
            ': ' +
            (row?.usedSum | numberFormat: false : true) +
            ' €'
          "
        >
          <span>{{ row?.usedSum | numberFormat: false : true }}</span
          ><span> €</span>
        </div>
        <div
          class="p-2.5"
          *ngIf="row?.usedPercentage !== 9999900"
          [tp]="
            ('reporting.used-percentage' | translate) +
            ': ' +
            (row?.usedPercentage | numberFormat: false : true) +
            ' %'
          "
        >
          <span>{{ row?.usedPercentage | numberFormat: false : true }}</span
          ><span> %</span>
        </div>
      </td>
    </ng-container>
  </ng-template>
  <div class="pt-2.5 pr-2.5 pb-2.5">
    {{ 'reporting.forecast-note' | translate }}
  </div>
</div>
