<div class="relative">
  <label *ngIf="labelText" class="text-base font-medium input-label flex"
    >{{ labelText }}
    <div
      *ngIf="helperService.isHelpModeEnabled$ | async"
      class="tooltip"
      tp="{{ tooltipText }}"
      data-tippy-open
      aria-describedby="tippy-6"
    >
      <span class="icon material-symbols-outlined input-info">info</span>
    </div>
  </label>
  <input
    type="text"
    [class.mt-2]="!noMargin"
    [disabled]="true"
    [ngModel]="selectedName"
    [placeholder]="placeholder"
    (blur)="onTouch(value)"
  />
  <span
    *ngIf="!isDisabled"
    (click)="openDialog()"
    class="material-symbols-outlined absolute h-9 bottom-0 right-0 flex items-end mr-2 pt-2 pb-2 cursor-pointer"
    >search
  </span>
</div>
