import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BudgetAreaReporting } from '../../budget/project/models/budget-area-reporting.model';
import { BudgetReporting } from '../../budget/project/models/budget-reporting.model';
import { Project } from '../../budget/project/models/project.model';
import { FiscalYearOperations } from '../operations/fiscal-year.operations';
import { TableConfiguration } from '../table/table.component';

@Component({
  selector: 'th-project-budget-reporting',
  templateUrl: './project-budget-reporting.component.html',
  styleUrls: ['./project-budget-reporting.component.scss'],
})
export class ProjectBudgetReportingComponent implements OnInit, OnChanges {
  @Input() data!: BudgetReporting;
  @Input() project!: Project;
  @Input() hideTicketLink = false;

  fyMonths: number[] = [];
  tableConfig: TableConfiguration | null = null;
  areas$: BehaviorSubject<BudgetAreaReporting[]> = new BehaviorSubject<
    BudgetAreaReporting[]
  >([]);

  expandedAreasMap: Map<string, boolean> = new Map();

  toggleBudgetAreaExpansion(area: BudgetAreaReporting): void {
    const isExpanded = this.expandedAreasMap.get(area.name) || false;
    this.expandedAreasMap.set(area.name, !isExpanded);
  }

  isAreaExpanded(area: BudgetAreaReporting): boolean {
    return this.expandedAreasMap.get(area.name) || false;
  }

  public get budgetSum(): number {
    return this.data?.budgetSum as number;
  }

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.initializeTable();
    this.areas$.subscribe(areas => {
      areas.forEach(area => {
        this.expandedAreasMap.set(area.name, true);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['data']?.previousValue !== changes?.['data']?.currentValue) {
      this.areas$.next(this.data.areas ?? []);
    }
  }

  private initializeTable(): void {
    this.fyMonths = FiscalYearOperations.getFiscalYearMonths(this.project);
    this.tableConfig = {
      columns: [
        {
          propertyKey: 'name',
          label: 'reporting.budgetArea',
          sortable: false,
          filterable: false,
        },
        ...this.fyMonths.map((month) => ({
          propertyKey: `month-${month}`,
          label: `general.months.month-${month}`,
          sortable: false,
          filterable: false,
        })),
        {
          propertyKey: 'actions',
          label: '',
          sortable: false,
          filterable: false,
        },
      ],
    };
  }

  // pure fn
  monthPercentage(budgetArea: BudgetAreaReporting, month: number): number {
    const part = budgetArea?.parts.find((part) => part.month === month);
    return part?.usedPercentage ?? 0;
  }

  // pure fn
  monthPlannedBudget(
    budgetArea: BudgetAreaReporting,
    month: number,
  ): number | string {
    const part = budgetArea?.parts.find((part) => part.month === month);
    return part?.budget ?? 0;
  }

  // pure fn
  monthUsedBudget(
    budgetArea: BudgetAreaReporting,
    month: number,
  ): number | string {
    const part = budgetArea?.parts.find((part) => part.month === month);
    return part?.usedSum ?? 0;
  }

  monthFullBudget(budgetAreas: BudgetAreaReporting[], month: number): number {
    const monthSum = budgetAreas.map(
      (budgetArea) =>
        budgetArea?.parts.find((part) => part.month === month)?.budget ?? 0,
    );
    return monthSum.reduce((a, b) => a + b, 0);
  }

  monthFullUsedBudget(
    budgetAreas: BudgetAreaReporting[],
    month: number,
  ): number {
    const monthSum = budgetAreas.map(
      (budgetArea) =>
        budgetArea?.parts.find((part) => part.month === month)?.usedSum ?? 0,
    );
    return monthSum.reduce((a, b) => a + b, 0);
  }

  hasMonthForecast(budgetArea: BudgetAreaReporting, month: number): boolean {
    const part = budgetArea?.parts.find((part) => part.month === month);
    return part?.isForecast ?? false;
  }

  // pure fn
  isUnscopedRow(row: BudgetAreaReporting): boolean {
    return row.name === 'reporting.unscoped-activities';
  }

  public getCellClass(budgetArea: BudgetAreaReporting, month: number): string {
    const part = budgetArea?.parts.find((part) => part.month === month);
    if ((part?.usedPercentage as number) >= 100) {
      return 'budget-red !p-0 border-b border-dark-attention-danger';
    } else if ((part?.usedPercentage as number) >= 70) {
      return 'budget-yellow !p-0 border-b border-dark-attention-warning1';
    } else {
      return 'budget-green !p-0 border-b border-dark-attention-success';
    }
  }

  public openTickets(row: BudgetAreaReporting): void {
    this.router.navigate(
      [`budget/projects/edit/${this.project?.id}/timetracking`],
      {
        onSameUrlNavigation: 'reload',
        queryParams: {
          fiscalYearId: this.data?.fiscalYearId,
          filterBudgetArea: this.isUnscopedRow(row) ? 'null' : row.name,
        },
      },
    );
  }
}
