/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModelsBudgetAreaExternalToolEntityViewModel } from './models-budget-area-external-tool-entity-view-model.model';
import { ModelsBudgetAreaInternalEntityViewModel } from './models-budget-area-internal-entity-view-model.model';


export interface ModelsBudgetAreaAssignmentsViewModel { 
    budgetAreaId?: number;
    epics?: Array<ModelsBudgetAreaInternalEntityViewModel>;
    externalOrders?: Array<ModelsBudgetAreaExternalToolEntityViewModel>;
    externalProjects?: Array<ModelsBudgetAreaExternalToolEntityViewModel>;
    externalTasks?: Array<ModelsBudgetAreaExternalToolEntityViewModel>;
    projectId?: number;
    ticketSystems?: Array<ModelsBudgetAreaInternalEntityViewModel>;
}

