import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { TableService } from '../../../shared/interfaces/table-service.interface';
import { PagedData } from '../../../shared/models/paged-data.model';
import { Pagination } from '../../../shared/models/pagination.model';
import { Query } from '../../../shared/models/query.model';
import { Company } from '../../models/company.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService implements TableService {
  constructor(private httpClient: HttpClient) {}

  loadData(
    pagination: Pagination,
    query: Query,
  ): Observable<PagedData<unknown>> {
    return this.getCompanies(query, pagination);
  }

  getFieldValues(): Observable<string[]> {
    throw new Error('Method not implemented.');
  }

  public getCompanies(
    query: Query,
    pagination: Pagination,
  ): Observable<PagedData<Company>> {
    return this.httpClient.get<PagedData<Company>>(
      `${environment.budgetApiUrl}/admin/companies/?Query.Page=${
        pagination.page
      }&Query.Size=${pagination.size}&q=${encodeURIComponent(
        JSON.stringify(query),
      )}`,
    );
  }

  public getCompany(id: number): Observable<Company> {
    return this.httpClient.get<Company>(
      `${environment.budgetApiUrl}/admin/companies/${id}`,
    );
  }

  public addCompany(company: Company): Observable<Company> {
    return this.httpClient.put<Company>(
      `${environment.budgetApiUrl}/admin/companies/`,
      company,
    );
  }

  public editCompany(company: Company): Observable<Company> {
    return this.httpClient.patch<Company>(
      `${environment.budgetApiUrl}/admin/companies/${company.id}`,
      company,
    );
  }

  public triggerImport(company: Company): Observable<Company> {
    return this.httpClient.post<Company>(
      `${environment.budgetApiUrl}/admin/companies/${company.id}/trigger-imports`,
      company,
    );
  }
}
