import { Route } from '@angular/router';
import { EmulationRequestsComponent } from './emulation-requests/emulation-requests.component';
import { AuthCallbackComponent } from './shared/auth-callback/auth-callback.component';
import { UserRole } from './shared/enums/user-role.enum';
import { AuthGuard } from './shared/services/auth-guard.service';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./app-selector/app-selector.module').then(
        (m) => m.AppSelectorModule,
      ),
    pathMatch: 'full',
  },
  {
    path: 'companies',
    canActivate: [AuthGuard],
    data: {
      title: 'companies.title',
      icon: 'domain',
      neededRoles: [UserRole.CompanyAdminRole],
      type: 'global',
    },
    loadChildren: () =>
      import('./company-mgmt/company-mgmt.module').then(
        (m) => m.CompanyMgmtModule,
      ),
  },
  {
    path: 'employees',
    canActivate: [AuthGuard],
    data: {
      title: 'employee.title',
      icon: 'group',
      neededRoles: [UserRole.CompanyAdminRole],
      type: 'global',
    },
    loadChildren: () =>
      import('./employee-mgmt/employee.module').then((m) => m.EmployeeModule),
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    data: {
      title: 'user.title',
      icon: 'person',
      neededRoles: [UserRole.AdminRole, UserRole.CompanyAdminRole],
      type: 'global',
    },
    loadChildren: () =>
      import('./user-mgmt/user.module').then((m) => m.UserModule),
  },
  {
    path: 'budget',
    canActivate: [AuthGuard],
    data: {
      type: 'app',
      neededRoles: [
        UserRole.ProjectControllerRole,
        UserRole.ProjectAdminRole,
        UserRole.CompanyAdminRole,
        UserRole.LeadRole,
        UserRole.CustomerRole,
      ],
    },
    loadChildren: () =>
      import('./budget/budget.module').then((m) => m.BudgetModule),
  },
  {
    path: 'timetracking',
    canActivate: [AuthGuard],
    data: {
      title: 'Time Tracking',
      neededRoles: [],
      type: 'app',
      icon: 'timer',
    },
    loadChildren: () =>
      import('./time-tracking/time-tracking.module').then(
        (m) => m.TimeTrackingModule,
      ),
  },
  {
    path: 'auth/callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'emulation-requests',
    canActivate: [AuthGuard],
    data: {
      isVisible: false,
    },
    component: EmulationRequestsComponent,
  },
];
