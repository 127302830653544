<label class="text-sm font-medium mb-2">{{ headline }}</label>
<div
  class="border border-dark-gray-700 rounded-lg box h-10 flex items-center p-1"
>
  <div
    class="bar-chart percentage rounded-lg flex items-center h-full justify-end bg-dark-gray-200"
    [style]="customWidth"
  >
    <!-- to do width custom und anzeige der stunden -->
    <!-- to do implement user-info -->
    <p class="hours font-semibold text-black mr-1">{{ value }}</p>
  </div>
</div>
