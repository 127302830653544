/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModelsCapacityEmployeeProjectAssignmentViewModel } from './models-capacity-employee-project-assignment-view-model.model';


export interface ModelsCapacityPlanningEmployeeViewModel { 
    capacityEmployeeProjectAssignments?: Array<ModelsCapacityEmployeeProjectAssignmentViewModel>;
    capacityPlanningId?: number;
    employeeId?: number;
    id?: number;
    maxEmployeeWorkingHours?: number;
    profession?: string;
    seniority?: string;
}

