/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ModelsTicketSystemViewModel { 
    epicField?: string;
    estimationField?: string;
    estimationTagRegex?: string;
    fallbackToTaskName?: boolean;
    id?: number;
    importSprints?: boolean;
    password?: string;
    projectId?: number;
    proxyUrl?: string;
    sprintTagRegex?: string;
    sprintsField?: string;
    ticketPrefix?: string;
    type?: string;
    updateTickets?: boolean;
    url?: string;
    useProxy?: boolean;
    user?: string;
}

