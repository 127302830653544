import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, map, of } from 'rxjs';
import { DialogComponent, DialogData } from '../../../dialog/dialog.component';
import { IDialog } from '../../../dialog/dialog.interface';
import { TableService } from '../../../interfaces/table-service.interface';
import { InternalTableColumn } from '../../table.component';

export interface TableFilterDialogData extends DialogData {
  columns: InternalTableColumn[];
  service: TableService;
  serviceContext?: object;
  filterState: FilterState;
  translationKeyPrefix: string;
}

interface TableFilter {
  column: InternalTableColumn;
  fieldValues?: any[];
  selectedFilterValues?: any[];
  isIntermediate: boolean;
}

type FilterState = { [key: string]: unknown };

@Component({
  selector: 'th-table-filter-dialog',
  templateUrl: './table-filter-dialog.component.html',
  styleUrls: ['./table-filter-dialog.component.scss'],
})
export class TableFilterDialogComponent
  implements IDialog<TableFilterDialogData, FilterState>, OnInit
{
  subject = new BehaviorSubject({});
  dialogData!: TableFilterDialogData;
  filters!: TableFilter[];

  @ViewChild('dialog') dialog!: DialogComponent;

  filterState: FilterState = {};

  public confirm(): void {
    for (const filter of this.filters) {
      if (
        !filter.selectedFilterValues ||
        (Array.isArray(filter.selectedFilterValues) &&
          !filter.selectedFilterValues.length)
      ) {
        delete this.filterState[
          filter.column.filterKey ?? filter.column.propertyKey
        ];
      } else {
        this.filterState[filter.column.filterKey ?? filter.column.propertyKey] =
          filter.selectedFilterValues;
      }
    }

    this.subject.next(this.filterState);
    this.dialog.dialogRef.nativeElement.close();
  }

  public reset(): void {
    this.subject.next({});
    this.dialog.dialogRef.nativeElement.close();
  }

  public ngOnInit(): void {
    const filterableColumns = this.dialogData.columns.filter(
      (col) => col.filterable,
    );

    // init intermediate filters first to avoid window resizing
    this.filters = filterableColumns.map((col) =>
      this.initIntermediateColumns(col),
    );
    const $observables = filterableColumns.map((col) =>
      this.initColumnData(col),
    );
    combineLatest($observables).subscribe(
      (filters) => (this.filters = filters),
    );
  }

  private initColumnData(column: InternalTableColumn): Observable<TableFilter> {
    const filterKey = column.filterKey || column.propertyKey;
    let observable$ = of([] as any[]);
    if (column.filterType === 'dropdown') {
      observable$ = this.dialogData.service
        .getFieldValues(
          { page: 1, size: 20 },
          filterKey,
          this.dialogData.serviceContext,
        )
        .pipe(map((items) => items.map((i) => ({ id: i, name: i }))));
    }

    return observable$.pipe(
      map((values) => ({
        column,
        isIntermediate: false,
        fieldValues: values,
        selectedFilterValues:
          (this.dialogData.filterState[filterKey] as any[]) ?? [],
      })),
    );
  }

  private initIntermediateColumns(column: InternalTableColumn): TableFilter {
    const filterKey = column.filterKey || column.propertyKey;
    return {
      column: { ...column, filterType: 'text' },
      isIntermediate: true,
      fieldValues: [],
      selectedFilterValues:
        (this.dialogData.filterState[filterKey] as any[]) ?? [],
    };
  }
}
