import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, map, of, tap, switchMap } from 'rxjs';
import { EmployeeProject } from '../../budget/project/models/employee-project.model';
import { Employee } from '../../budget/project/models/employee.model';
import { PagedData } from '../../shared/models/paged-data.model';
import { Query } from '../../shared/models/query.model';
import { BaseState } from '../../shared/state/base.state';
import { Selector } from '../../shared/state/types';
import { EmployeeService } from '../components/services/employee.service';
import { DropdownItem } from '../../shared/dropdown/dropwdown-item.model';

interface EmployeeStateModel {
  projects?: EmployeeProject[] | [];
  employees: Employee[];
  currentEmployee: Employee | null;
  isEditModeActive: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EmployeeState extends BaseState<EmployeeStateModel> {
  constructor(
    private employeeService: EmployeeService,
    private destroyRef: DestroyRef,
  ) {
    super(
      {
        projects: [],
        employees: [],
        currentEmployee: null,
        isEditModeActive: false,
      },
      { useImmer: false },
    );
  }

  public employees$ = this.select(({ employees }) => employees);
  isEditModeActive$: Selector<boolean> = this.select(
    ({ isEditModeActive }) => isEditModeActive,
  );

  public currentEmployee$ = this.select(
    ({ currentEmployee }) => currentEmployee as Employee,
  );

  public projects$ = this.select(
    ({ projects }) => projects as EmployeeProject[],
  );

  headEmployeeOptions$: Observable<DropdownItem[]> = this.currentEmployee$.pipe(
    switchMap(currentEmployee =>
      this.employeeService
        .getValidHeadEmployees(currentEmployee.id)
        .pipe(
          map((employees: Employee[]) =>
            employees.filter(employee => employee.id !== currentEmployee.id)
              .map((employee: Employee) => ({
                name: `${employee.firstName} ${employee.lastName}`,
                id: employee.id,
              }))
          )
        )
    )
  );

  employeeOptions$: Observable<Employee[]> = this.employeeService
    .getEmployees(null as never, { page: 1, size: 50 })
    .pipe(map((result) => result.content));

  public toggleEditMode(): void {
    this.updateState((state) => {
      state.isEditModeActive = !state.isEditModeActive;
    });
  }

  public get currentEmployee(): Employee {
    return this.selectSnapshot(
      ({ currentEmployee }) => currentEmployee,
    ) as Employee;
  }

  public setCurrentEmployee(
    employeeId: number | null,
  ): Observable<Employee | null> {
    if (employeeId === null) {
      this.updateState((state) => {
        state.currentEmployee = null;
        state.projects = [];
      });
      return of(null);
    } else if (employeeId === 0) {
      const employee = {
        id: 0,
      } as Employee;
      this.updateState((state) => {
        state.currentEmployee = employee;
        state.isEditModeActive = true;
        state.projects = [];
      });
      return of(employee);
    }
    return this.employeeService.getEmployee(employeeId).pipe(
      tap((employee) => {
        this.updateState((state) => {
          state.currentEmployee = employee;
        });
      }),
    );
  }

  public loadEmployeeProjects(
    employeeId: number,
  ): Observable<EmployeeProject[]> {
    return this.employeeService.getEmployeeProjects(employeeId).pipe(
      tap((project) => {
        this.updateState((state) => {
          state.projects = project as EmployeeProject[];
        });
      }),
    );
  }

  public loadEmployees(): Observable<PagedData<Employee>> {
    return this.employeeService
      .getEmployees({ where: {} } as Query, { page: 1, size: 50 })
      .pipe(
        tap((employees) => {
          this.updateState((state) => {
            state.employees = employees.content;
          });
        }),
      );
  }

  public saveCurrentEmployee(): void {
    this.employeeService
      .updateEmployee(this.currentEmployee)
      .pipe(
        tap((employee) =>
          this.updateState((state) => {
            state.currentEmployee = employee;
          }),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
