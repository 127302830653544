/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CoreExternalToolViewModel { 
    companyId?: number;
    database?: string;
    id?: number;
    password?: string;
    type?: string;
    url?: string;
    user?: string;
}

