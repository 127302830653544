/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BudgetEpic { 
    createdAt?: string;
    fiscalYearId?: number;
    id?: number;
    identifier?: string;
    name?: string;
    projectId?: number;
    updatedAt?: string;
}

