import { Component, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DialogComponent, DialogData } from '../dialog/dialog.component';
import { IDialog } from '../dialog/dialog.interface';

export interface ConfirmationDialogData extends DialogData {
  description: string;
}

@Component({
  selector: 'th-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent
  implements IDialog<ConfirmationDialogData, boolean>
{
  subject = new BehaviorSubject(false);
  dialogData!: ConfirmationDialogData;
  @ViewChild('dialog') dialog!: DialogComponent;

  public confirm(): void {
    this.subject.next(true);
    this.dialog.dialogRef.nativeElement.close();
  }

  public cancel(): void {
    this.subject.next(false);
    this.dialog.dialogRef.nativeElement.close();
  }
}
