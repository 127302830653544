import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DialogComponent, DialogData } from '../../dialog/dialog.component';
import { IDialog } from '../../dialog/dialog.interface';
import { DropdownItem } from '../../dropdown/dropwdown-item.model';
import { ExternalToolItem } from '../../models/external-tool-item.model';
import { IdName } from '../../models/id-name.model';

export interface AssignmentInputDialogData extends DialogData {
  items: ExternalToolItem[];
  lazyLoadItems?: (page: number) => Observable<DropdownItem[]>;
}

@Component({
  selector: 'th-assignment-input-dialog',
  templateUrl: './assignment-input-dialog.component.html',
  styleUrls: ['./assignment-input-dialog.component.scss'],
})
export class AssignmentInputDialogComponent
  implements
    IDialog<AssignmentInputDialogData, { item: ExternalToolItem | null }>,
    OnInit
{
  subject = new BehaviorSubject({
    item: null as unknown as null | ExternalToolItem,
  });
  dialogData!: AssignmentInputDialogData;
  @ViewChild('dialog') dialog!: DialogComponent;

  items!: IdName<string>[];
  lazyLoadItems: ((page: number) => Observable<DropdownItem[]>) | null = null;
  id!: string | null;

  ngOnInit(): void {
    this.items = this.dialogData.items.map((i) => i.item);
    this.lazyLoadItems = this.dialogData.lazyLoadItems ?? null;
  }

  public confirm(): void {
    this.subject.next({
      item: this.dialogData.items.find((i) => i.item.id === this.id) ?? null,
    });
    this.dialog.dialogRef.nativeElement.close();
  }

  public cancel(): void {
    this.subject.next({ item: null });
    this.dialog.dialogRef.nativeElement.close();
  }
}
