import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, tap } from 'rxjs';
import { NavigationItem } from '../models/navigation-item.model';
import {
  NavigationService,
  RouteNameResolver,
  TownhallApplication,
} from '../services/navigation.service';
import { ScreenSizeService } from '../services/screensize.service';

@Component({
  selector: 'th-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent implements OnInit {
  @Input({ required: true }) nameResolver!: RouteNameResolver | null;
  @Input({ required: true }) app!: TownhallApplication;

  public navigationItems!: NavigationItem[];

  constructor(
    public screenSizeService: ScreenSizeService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService,
    private destroyRef: DestroyRef,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.navigationService
      .getBreadcrumbsFromRoute(this.activatedRoute, this.app, this.nameResolver)
      .pipe(
        tap((items) => (this.navigationItems = items)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private navigate(link: string): void {
    if (link) {
      this.router.navigateByUrl(`/${link}`);
    }
  }

  handleNavigation(item: NavigationItem, last: boolean): void {
    combineLatest([
      item.title$,
      this.screenSizeService.isSmallScreen$,
    ]).subscribe(([title, isSmallScreen]) => {
      if (
        (!last && !isSmallScreen) ||
        (isSmallScreen && (title || item.link))
      ) {
        this.navigate(item.link);
      }
    });
  }
}
