/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RepositoriesWidget } from './repositories-widget.model';


export interface RepositoriesDashboardViewModel { 
    id?: number;
    name?: string;
    type?: string;
    userId?: number;
    widgets?: Array<RepositoriesWidget>;
}

