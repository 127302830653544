<div class="flex flex-col" [class.h-full]="adjustHeight">
  <p *ngIf="title" class="mb-2.5 text-base font-bold">
    {{ title | translate }}
  </p>
  <!-- <th-user-info
    *ngIf="tooltipText"
    [text]="tooltipText | translate"
    type="info"
  ></th-user-info> -->
  <div class="border border-dark-gray-700 bg-dark-gray-800 rounded-md grow">
    <div class="inner-content flex flex-col gap-5 m-4">
      <ng-content></ng-content>
    </div>
  </div>
</div>
