import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { PagedData } from '../../../shared/models/paged-data.model';
import { Query } from '../../../shared/models/query.model';
import { ExternalTool } from '../../models/external-tool.model';

@Injectable({
  providedIn: 'root',
})
export class ExternalToolManagementService {
  constructor(private httpClient: HttpClient) {}

  public getExternalToolsByCompany(
    companyId: number,
  ): Observable<PagedData<ExternalTool>> {
    const query = {
      where: {
        AND: [{ key: 'companyId', operator: 'equals', value: companyId }],
      },
    } as Query;
    const params = new HttpParams().append('q', JSON.stringify(query));

    return this.httpClient.get<PagedData<ExternalTool>>(
      `${environment.budgetApiUrl}/admin/companies/${companyId}/external-tools/`,
      { params },
    );
  }

  public addExternalTool(body: ExternalTool): Observable<ExternalTool> {
    return this.httpClient.put<ExternalTool>(
      `${environment.budgetApiUrl}/admin/companies/${body.companyId}/external-tools/`,
      body,
    );
  }

  public editExternalTool(body: ExternalTool): Observable<ExternalTool> {
    return this.httpClient.patch<ExternalTool>(
      `${environment.budgetApiUrl}/admin/companies/${body.companyId}/external-tools/${body.id}`,
      body,
    );
  }

  public deleteExternalTool(
    id: number,
    companyId: number,
  ): Observable<unknown> {
    return this.httpClient.delete(
      `${environment.budgetApiUrl}/admin/companies/${companyId}/external-tools/${id}`,
    );
  }
}
