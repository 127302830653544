/**
 * Townhall API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BudgetExternalToolEntity { 
    createdAt?: string;
    entityType?: string;
    externalRef?: { [key: string]: string; };
    id?: number;
    projectId?: number;
    updatedAt?: string;
    validFrom?: string;
    validUntil?: string;
}

